import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import DatePicker from './components/date-picker';
import ROUTES from '../../../../platform/constants/routes';
import { TabletScreen, SmallComputerScreen, PhoneScreen } from '../../../../platform/constants';

import ProgrammApis from '../../../../platform/api/programm';

import Translations from '../../../../platform/services/translations';

import './style.scss';

class Posts extends Component {
  state = {
    data: [],
    itemsCount: 3,
  }

  componentDidMount = async () => {
    const response = await ProgrammApis.getHomeList();
    if (response && response.success) 
      this.setState({ data: response.data });

    this.checkForScreens();
    window.addEventListener('resize', this.checkForScreens);
  }

  componentWillUnmount() { window.removeEventListener('resize', this.checkForScreens); }

  checkForScreens = () => {
    const { itemsCount } = this.state;
    if (window.innerWidth < PhoneScreen) itemsCount !== 1 && this.setState({ itemsCount: 1 });
    else if (window.innerWidth < TabletScreen) itemsCount !== 2 && this.setState({ itemsCount: 2 });
    else if (window.innerWidth < SmallComputerScreen) itemsCount !== 1 && this.setState({ itemsCount: 1 });
    else if (window.innerWidth < 1600) itemsCount !== 3 && this.setState({ itemsCount: 3 });
    else itemsCount !== 3 && this.setState({ itemsCount: 3 });
  }

  render() {
    const { data, itemsCount } = this.state;

    return (
      <div className="Q-home-posts">
        <DatePicker />
        {
          data.length 
          ?
          <Slider infinite slidesToShow={data.length < itemsCount ? data.length : itemsCount} slidesToScroll={itemsCount} dots arrows={false}>
          {
            data.map(item => (
          <Link style={{ textDecoration: 'none' }} to={ROUTES.PROGRAM_DETAILS.replace(':id', item.id)}>
            <div key={item.id} className="Q-news-posts-item">
              <div className="Q-cover" style={{ background: `url(${item.image}) center/cover` }} />
              <div className="Q-details">
                <h4>{getFormatted(item.name)}</h4>
                <p>{getFormatted(item.description)}</p>
                <Link to={ROUTES.PROGRAM_DETAILS.replace(':id', item.id)} className="Q-G-radius-button">{Translations.texts.read_ptogramm}</Link>
              </div>
            </div>
          </Link>
            ))
          }
          </Slider>
          :
          undefined
        }
      </div>
    )
  }
}

function getFormatted (description) {
	let formatted = description.substr(0, 210);
	if ([':', '...'].includes(formatted[formatted.length - 1])) {
		return description;
	} else if ([',', '`', '.'].includes(formatted[formatted.length - 1])) {
		return formatted.substr(0, formatted.length - 1) + '...'
	} else if (description.length <= formatted.length ) {
        return description;
    }
	else {
		return formatted + '...'
	}
}

export default Posts;