import { LanguageEnum } from "../constants/enums";

class Settings {

    static defaultLanguage = LanguageEnum.Armenian;

    static get Language() {
        const language = window.localStorage.getItem('language');
        if (!Object.values(LanguageEnum).find(item => item === language)) return Settings.defaultLanguage;
        
        return language;
    }

    static set Language(value) {
        window.localStorage.setItem('language', value);
        window.location.reload();
    }
};

export default Settings;