import React, { Component } from 'react';

import Translations from '../../../../platform/services/translations';
import Modal from '../../../../components/modal';
import NewspapersController from '../../../../platform/api/newspapers';

import DownloadIcon from '../../../../assets/images/download.png';
import EyeIcon from '../../../../assets/images/eye.png';

import './style.scss';
import { isChrome } from '../../../../platform/services/browser';

class Book extends Component {

  state = {
    details: '',
    isBookModalOpen: false
  }

  componentDidMount () {
    if (this.props.details){
      this.setState({ details: this.props.details });
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.details) this.setState({ details: nextProps.details });
  }

  download = () => {
    const { details } = this.state;
    const a = document.createElement('a');
    a.href = details.download_link;
    a.target = '_blank';
    a.download = true;
    document.body.appendChild(a);
    a.click();
    details.download_count++;
    this.setState({ details });
    NewspapersController.addDownload(details.id);
  }

  openBook = () => {
    const { details } = this.state;
    NewspapersController.addView(details.id);
    details.view_count++;
    if (isChrome()) {
      this.setState({ isBookModalOpen: true, details });  
    } else {
      const a = document.createElement('a');
      a.href = details.file_link;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
    }
  };
  
  closeBookModal = () => this.setState({ isBookModalOpen: false });

  render () {

    const { details, isBookModalOpen } = this.state;

    return (
      <>
        <div className="Q-newspaper-book">
          <div className="Q-cover">
            <div style={{ background: `url("${details.featured_image}") center/cover` }}>
              <h4 onClick={this.openBook}>{details.title}</h4>
            </div>
          </div>
          <div className="Q-details">
            <h5 className="Q-statistics-info"><img src={EyeIcon} alt="eye" /><span className="Q-info-number"> {details.view_count}</span></h5>
            <h5 className="Q-statistics-info"><img src={DownloadIcon} alt="download" /><span className="Q-info-number"> {details.download_count}</span></h5>
            <button className="Q-download-button" download onClick={this.download}>{Translations.texts.download}</button>
            <button className="Q-G-radius-button" onClick={this.openBook}>{Translations.texts.read}</button>
          </div>
        </div>
        {isBookModalOpen && <Modal onClose={this.closeBookModal}>
          <object data={details.file_link} aria-label='text' className="Q-G-pdf-modal" />
        </Modal>}
      </>
    );
  }
}

export default Book;