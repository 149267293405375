import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';

import ROUTES from '../../../../platform/constants/routes';
import EventsController from '../../../../platform/api/events';
import Translations from '../../../../platform/services/translations';
import Modal from '../../../../components/modal';

import CalendarIcon from '../../../../assets/images/calendar.png';

import './style.scss';

class Details extends PureComponent {

  state = {
    data: null,
    details: null,

    confirmed: false
  };

  componentDidMount() {
    this.fetchData();
    this.historyUnlisten = window.routerHistory.listen(() => setTimeout(this.fetchData, 0));
  }

  componentWillUnmount() { this.historyUnlisten(); }

  fetchData = async () => {
    const { id } = this.props.match.params;
    const result = await EventsController.details(id);

    if (!result || !result.success) window.routerHistory.push(ROUTES.HOME);
    else this.setState({ data: result.data });
  }

  prev = () => {
    const { data } = this.state;
    window.routerHistory.replace(ROUTES.EVENTS_DETAILS.replace(':id', data.related.prev));
  }

  next = () => {
    const { data } = this.state;
    window.routerHistory.replace(ROUTES.EVENTS_DETAILS.replace(':id', data.related.next));
  }

  getFormattedDate = date => {
    const splited = date.split(' ')[0];
    const dateArr = splited.split('-');
    return `${dateArr[0]}-${Translations.texts.months[+dateArr[1] - 1]}-${dateArr[2]}`;
  }

  formatDate = (date, withHours) => {
    if (!date) return 'N/A';
    const localDate = new Date(date);
    const months = Translations.texts.months;
  
    const onlyHours = localDate.getHours() < 10 ? `0${localDate.getHours()}` : localDate.getHours();
    const onlyMinutes = localDate.getMinutes() < 10 ? `0${localDate.getMinutes()}` : localDate.getMinutes();
  
    const hours = `${onlyHours}:${onlyMinutes}`;
  
    return `${localDate.getDate()} ${months[localDate.getMonth()]} ${localDate.getFullYear()}${withHours ? ` | ${hours}` : ''}`;
  }

  getGoButtonText = (startDate,endDate) =>  {
    const { data } = this.state;
    const parseEndDate = Date.parse(endDate);
    const parseStartDate = Date.parse(startDate);
    if (parseEndDate > Date.now()) {
    return Translations.texts.eventgo + `(${data.memberCount})`
    } else if (parseEndDate) {
      return Translations.texts.eventend
    } else if (parseEndDate < Date.now()) {
      return Translations.texts.eventend
    } else if (parseStartDate > Date.now()) {
      return Translations.texts.eventgo + `(${data.memberCount})`
    } else if (parseStartDate === parseEndDate && parseStartDate < Date.now()) {
      return Translations.texts.eventend
    } else if (parseStartDate === parseEndDate && parseStartDate > Date.now()) {
      return Translations.texts.eventgo + `(${data.memberCount})`
    } else{
      return Translations.texts.eventend
    }
  }

  participant = () => {
    const { data, confirmed } = this.state;
    let toLetParticipate = false;
    if (data.end_date > Date.now()) {
      toLetParticipate = true;
    } else if (data.end_date) {
      // Translations.texts.eventend
    } else if (data.end_date < Date.now()) {
      // Translations.texts.eventend
    } else if (data.start_date > Date.now()) {
      toLetParticipate = true;
    } else if (data.start_date === data.end_date && data.start_date < Date.now()) {
      //  ranslations.texts.eventend
    } else if (data.start_date === data.end_date && data.start_date > Date.now()) {
      toLetParticipate = true;
    }
    if (!confirmed && toLetParticipate) {
      EventsController.participate({ id: data.id });
      data.memberCount++;
      this.setState({ data, confirmed: true });
    }
  }

  render() {
    const { data, details } = this.state;
    return (

      <section className="Q-event-details">
        {data ? <>
          <div className="Q-cover" style={{ background: `url("${data.cover}") center/cover` }} />
          <div className="Q-wrapper">
            <div className="Q-content">
              <h3>{data.title}</h3>
              <h5>
                <img src={CalendarIcon} alt="calendar" />
                <span>{data.start_date === data.end_date ?
                  this.formatDate(data.start_date) :
                  `${this.formatDate(data.start_date)} - ${this.formatDate(data.end_date)}`}
                </span>
              </h5>
              <h5><i className="icon-ic_location" /> <span>{data.place}</span></h5>
              <div className="Q-htmlBlock" dangerouslySetInnerHTML={{ __html: data.body }} />


              <div className="Q-map-section">
              <div className="Q-event-map-block">
                <iframe
                    className="Q-event-map"
                    title="Google Maps"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3048.7492287206123!2d44.51458351570097!3d40.17013677852327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abc5f76865a53%3A0xae85bd9868162506!2s20+Yervand+Kochar+St%2C+Yerevan%2C+Armenia!5e0!3m2!1sen!2s!4v1554718856802!5m2!1sen!2s"
                    allowFullScreen
                />
              </div>
                <div className="Q-map-go">
                <button className="Q-map-button" type="button" onClick={this.participant}>{this.getGoButtonText(data.start_date,data.end_date)}</button>
                </div>
              </div>


              {!!data.attached.photos.length && <>
                <h3>{Translations.texts.photos}</h3>
                <div className="Q-details-photos">
                  {data.attached.photos.map(item => <div
                    key={item.id}
                    style={{ background: `url("${item.image}") center/cover` }}
                  >
                    <h3 onClick={() => this.setState({ details: item })}>{item.title}</h3>
                  </div>)}
                </div>
              </>}
              {!!data.attached.files.length && <>
                <h3>{Translations.texts.files}</h3>
                <div className="Q-details-files">
                  {data.attached.files.map(item => <a
                    key={item.id}
                    href={item.image}
                    download
                  >
                    {item.name}
                  </a>)}
                </div>
              </>}
            </div>
            {!!data.related.recomended.length && <div className="Q-recomended">
              <h4>{Translations.texts.recomended}</h4>
              {data.related.recomended.map(item => <Link key={item.id} to={ROUTES.EVENTS_DETAILS.replace(':id', item.id)}>
                <div className="Q-image" style={{ background: `url("${item.image}") center/cover` }} />
                <div className="Q-content">
                  <h4>{getFormatted(item.title)}</h4>
                  <h5><img src={CalendarIcon} alt="calendar" /> <span>{this.formatDate(item.start_date)} - {this.formatDate(item.end_date)}</span></h5>
                </div>
              </Link>)}
            </div>}
            {(!!data.related.prev || !!data.related.next) && <div className="Q-bottom">
              {!!data.related.prev && <button onClick={this.prev} className="Q-prev"><i className="icon-ic_back" /> <span>{Translations.texts.prev}</span></button>}
              {!!data.related.next && <button onClick={this.next} className="Q-next"><span>{Translations.texts.next}</span> <i className="icon-ic_next" /></button>}
            </div>}
          </div>
          {details && <Modal onClose={() => this.setState({ details: null })}>
            <div
              className="Q-G-media-details-modal"
              style={{ background: `url("${details.image}") center/cover` }}
            />
          </Modal>}
        </> : null}
      </section>
    );
  }
};

function getFormatted (description) {
	let formatted = description.split(' ').slice(0, 40).join(' ');
	if ([':', '...'].includes(formatted[formatted.length - 1])) {
		return formatted;
	} else if ([',', '`', '.'].includes(formatted[formatted.length - 1])) {
		return formatted.substr(0, formatted.length - 1) + '...'
	} else {
		return formatted + '...'
	}
}

export default withRouter(Details);