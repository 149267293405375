import React, { Component } from 'react';

import Form from './components/form';
import Translations from '../../platform/services/translations';

import ContactUsCover from '../../assets/images/contact_us.jpg';

import fileApis from '../../platform/api/file';

import './style.scss';
import { pageCoverTypeEnum } from '../../platform/constants/enums';

class ContactUs extends Component {

  state = {
    cover: ''
  }

  componentDidMount  () {
    this.getCover();
  }

  getCover = async() => {
    const result = await fileApis.getCover(pageCoverTypeEnum.contactUs);
    if (result && result.success) this.setState({ cover: result.data });
    else this.setState({ cover: ContactUsCover });
  }

  render() {

    return(
      <section className="Q-contact-us">
        <div style={{ background: `url("${this.state.cover}") center/cover`, width: '100%', height: '100%' }}>
          <h1 className="Q-G-page-starting">{Translations.texts.contact_us}</h1>
        </div>
        <div className="Q-content">
          <div className="Q-info">
            <div className="Q-info-block">
              <i className="icon-ic_location"/>
              <h4>{Translations.texts.address}</h4>
              <p>{Translations.texts.addressValue}</p>
            </div>
            <div className="Q-info-block">
              <i className="icon-ic_phone"/>
              <h4>{Translations.texts.phone}</h4>
              <p>(011) 531-397, (011) 531-398</p>
            </div>
            <div className="Q-info-block">
              <i className="icon-ic_mail"/>
              <h4>{Translations.texts.email}</h4>
              <p>info@cfoamail.am</p>
            </div>
          </div>
          <Form />
        </div>
      </section>
    );
  }

}

export default ContactUs;

// const ContactUs = memo(() => (
//   <section className="Q-contact-us">
//     <div style={{ background: `url("${ContactUsCover}") center/cover`, width: '100%', height: '100%' }}>
//       <h1 className="Q-G-page-starting">{Translations.texts.contact_us}</h1>
//     </div>
//     <div className="Q-content">
//       <div className="Q-info">
//         <div className="Q-info-block">
//           <i className="icon-ic_location"/>
//           <h4>{Translations.texts.address}</h4>
//           <p>Yerevan, Yervand Qochar's 20</p>
//         </div>
//         <div className="Q-info-block">
//           <i className="icon-ic_phone"/>
//           <h4>{Translations.texts.phone}</h4>
//           <p>(011) 531-397, (011) 531-398</p>
//         </div>
//         <div className="Q-info-block">
//           <i className="icon-ic_mail"/>
//           <h4>{Translations.texts.email}</h4>
//           <p>info@cfoamail.am</p>
//         </div>
//       </div>
//       <Form />
//     </div>
//   </section>
// ));