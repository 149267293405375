import Connection from "../services/connectionV2";

const controllerName = 'file';

class FileController {

  static getCover = async key => {
    const result = await Connection.GET(controllerName, `cover?key=${key}`);
    return result;
  }

  static calcUser = async () => {
    const result = await Connection.GET(controllerName, 'calcUser');
    return result;
  }
};

export default FileController;