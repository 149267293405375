import React, { PureComponent } from 'react';

import Filter from './components/filter';
import ManageItem from './components/Manage';
import Translations from '../../platform/services/translations';

import programApis from '../../platform/api/programm';
import fileApis from '../../platform/api/file';

// import { scrolledToBottom } from '../../platform/services/helper';

import programmsCover from '../../assets/images/programms.jpg'

import Pagination from '../../components/pagination';

import './style.scss';
// import Connection from '../../platform/services/connection';
import { pageCoverTypeEnum } from '../../platform/constants/enums';

class Programs extends PureComponent {

  state = {
    data: [],
    filter: {},
    page: 1,
    perPage: 10,
    pageCount: 1,
    loading: false,
    cover: ''
  }

  componentDidMount = async() => {
    try {
      const cachedState = sessionStorage.getItem('programsCachedState');
      if (cachedState) this.setState(JSON.parse(cachedState));
    } catch (e) {}

    setTimeout(() => {
      this.getList();
      this.getCover();
    }, 0);
    // window.addEventListener('scroll', this.onScroll);
  }

  getList = async () => {
    // if (overwrite) Connection.AbortAll();
    const { filter, page, perPage } = this.state;
    this.setState({ loading: true }, async () => {
      const result = await programApis.getList({ ...filter, page, perPage });
      if (result && result.success) {
        this.setState({
          data: result.data.itemList,
          pageCount: result.data.pageCount,
          loading: false
        });
      } else if (result && !result.success) {
        sessionStorage.removeItem('programsCachedState');
        window.location.reload();
      }
    });
  }

  getCover = async() => {
    const result = await fileApis.getCover(pageCoverTypeEnum.programs);
    if (result && result.success) this.setState({ cover: result.data });
    else this.setState({ cover: programmsCover });
  }

  // componentWillUnmount () {
  //   window.removeEventListener('scroll', this.onScroll);
  // }

  // onScroll = () => {
  //   if (scrolledToBottom() && this.state.page !== this.state.pageCount && !this.state.loading) {
  //     this.setState({ page: this.state.page + 1 }, async () => {
  //       this.getList();
  //     })
  //   }
  // }

  onFilterChange = filter => this.setState({ filter, page: 1 }, () => this.getList(true));
  
  getListByPageNo = (page) => {
		this.setState({page: page}, () => {
			this.getList();
		});
  }
  
  componentWillUnmount() {
    const { filter, page } = this.state;

    sessionStorage.setItem('programsCachedState', JSON.stringify({
      filter,
      page,
    }));
  }

  componentDidCatch() { sessionStorage.removeItem('programsCachedState'); }

  render() {

    const { data, cover, pageCount, page, filter } = this.state;

    return (
      <section className="Q-program">
        <div style={{ background: `url("${cover}") center/cover`, width: '100%', height: '100%' }}>
          <h1 className="Q-G-page-starting">{Translations.texts.programs}</h1>
        </div>
        <div className="Q-content">
          <Filter onChange={this.onFilterChange} filter={filter} />
          <div className="Q-list">
            {
              data.map((item, index) => <ManageItem key={index} item={item}/>)
            }
            <Pagination pageNo={page} lastPageNo={pageCount} pageCount={pageCount} getList={this.getListByPageNo}/>
          </div>
        </div>
      </section>
    );
  }
}

export default Programs;