import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import eventApis from '../../../../../../platform/api/events';
import ROUTES from '../../../../../../platform/constants/routes';

import Translations from '../../../../../../platform/services/translations';

import './style.scss';


class DatePicker extends Component {

  state = {
    data: []
  }

  componentDidMount = async() => {
    const response = await eventApis.getHomeList();
    if (response && response.success) {
      this.setState({ data: response.data });
    }
  }



  render () {
    const { data } = this.state;

    return (
      <div className="Q-home-posts-date-picker">
        {
          data.map(item => (
          <Link to={ROUTES.EVENTS_DETAILS.replace(':id', item.id)} key={item.id} className="Q-picker-item">
            <div className="Q-G-date-block">
              <h2>{new Date(item.date).getDate()}</h2>
              <h5>{Translations.texts.months_long[new Date(item.date).getMonth()]}</h5>
            </div>
            <h4>{getFormatted(item.name)}</h4>
            <button className="Q-G-radius-button Q-G-ghost-button">{Translations.texts.read}</button>
          </Link>
          ))
        }
      </div>
    )

  }

}

function getFormatted (description) {
	let formatted = description.substr(0, 50);
	if ([':', '...'].includes(formatted[formatted.length - 1])) {
		return formatted;
	} else if ([',', '`', '.'].includes(formatted[formatted.length - 1])) {
		return formatted.substr(0, formatted.length - 1) + '...'
	} else {
		return formatted + '...'
	}
}


export default DatePicker;