import React, { Component } from 'react';

import Translations from '../../platform/services/translations';
import { TwitterSocialLink, LinkedinSocialLink, FacebookSocialLink } from '../../platform/constants';

import Validator from '../../platform/services/validator';
import ContactController from '../../platform/api/contact';

import LogoImage from '../../assets/images/logo.png';
import PhoneImage from '../../assets/images/phone.png';
import LocationImage from '../../assets/images/location.png';
import EmailImage from '../../assets/images/email.png';
import LinkedinImage from '../../assets/images/linkedin.png';
import TwitterImage from '../../assets/images/twitter.png';
import FacebookImage from '../../assets/images/facebook.png';

import MangoraIcon from '../../assets/images/mangora.png';

import './style.scss';

class Footer extends Component {
  state = {
    submited: false,
    form: {
      name: '',
      email: '',
      body: '',
    }
  }

  change = e => {
    const { form } = this.state;
    form[e.target.name] = e.target.value;
    this.setState({ form });
  }

  submit = async e => {
    e.preventDefault();
    const form = {...this.state.form};
    const { submited } = this.state;
    !submited && this.setState({ submited: true });

    if (form.name.length < 15 && Validator.isEmailValid(form.email) && form.body) {
      const result = await ContactController.NewMessage(form);
      if (result.status) {
        window.scrollTo(0, 0);
        this.setState({
          submited: false,
          form: {
            name: '',
            email: '',
            body: '',
          }
        });
      }
    }
  }

  render() {
    const { submited, form } = this.state;
  
    return ( 
      <footer>
        <div className="Q-footer-col">
        <div>
          <img src={LogoImage} alt="logo" className="Q-footer-logo" />
        </div>

          <div>
            <p className="Q-footer-desc">Copyright © 2019 Communities Finance Officers Assocation</p>
          </div>

          <div className="Q-powered">
              <img src={MangoraIcon} alt=""/>
              <h5>Powered by MangoraWeb</h5>
          </div>

          <div>
            
          </div>

          <div className="Q-footer-social">
            <a href={TwitterSocialLink} target="_blank" rel="noopener noreferrer"><img src={TwitterImage} alt="twitter" /></a>
            <a href={LinkedinSocialLink} target="_blank" rel="noopener noreferrer"><img src={LinkedinImage} alt="linkedin" /></a>
            <a href={FacebookSocialLink} target="_blank" rel="noopener noreferrer"><img src={FacebookImage} alt="facebook" /></a>
          </div>

        </div>
        <div className="Q-contact-top Q-footer-col">
          <h2 className="Q-G-block-title">{Translations.texts.contact_us}</h2>
          <h3>
            <img src={LocationImage} alt="location" />
            {Translations.texts.office_address}
          </h3>
          <h3>
            <img src={PhoneImage} alt="phone" />
            (011) 531-397<br />
            (011) 531-398
          </h3>
          <h3>
            <img src={EmailImage} alt="email" />
            info@cfoamail.am
          </h3>
        </div>
        <form className="Q-footer-col Q-footer-form">
          {/*<p>{Translations.texts.contact_form_text}</p>*/}
          <input className={submited && !form.name ? 'Q-G-invalid-field Q-G-field' : 'Q-G-field'} name="name" value={form.name} onChange={this.change} placeholder={Translations.texts.name} />
          <input className={submited && !Validator.isEmailValid(form.email) ? 'Q-G-invalid-field Q-G-field' : 'Q-G-field'} name="email" value={form.email} onChange={this.change} placeholder={Translations.texts.email} />
          <textarea className={submited && !form.body ? 'Q-G-invalid-field Q-G-field' : 'Q-G-field'} name="body" onChange={this.change} value={form.body} placeholder={Translations.texts.message} />
          <button className="Q-G-radius-button" onClick={this.submit}>{Translations.texts.send}</button>
        </form>
      </footer>
    )
  }
};

export default Footer;