import React, { Component } from 'react';
import Translations from '../../platform/services/translations';

import LeftArrow from '../../assets/images/left.svg';
import RightArrow from '../../assets/images/right.svg';
import DisableRightArrow from '../../assets/images/rigtdisable.svg';
import DisableLeftArrow from '../../assets/images/leftdisable.svg';

import './style.scss';

class Pagination extends Component {

  state = {
    pageCount  : 0,
    pageNo     : 1,
    lastPageNo : 0
  }

  componentDidMount() {
    this.setState({
      pageCount  : this.props.pageCount,
      pageNo     : this.props.pageNo,
      lastPageNo : this.props.pageNo
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      pageCount  : nextProps.pageCount,
      pageNo     : nextProps.pageNo,
      lastPageNo : nextProps.pageNo
    });
  }

  getNext = () => {
    let { pageCount, pageNo, lastPageNo } = this.state;
    if (!pageNo && lastPageNo < pageCount) {
      this.setState({ pageNo: ++lastPageNo, lastPageNo: ++lastPageNo }, () => {
        this.props.getList(this.state.pageNo);
      });
    } else if (pageNo < pageCount) {
      this.setState({ pageNo: ++pageNo, lastPageNo: ++pageNo }, () => {
        this.props.getList(this.state.pageNo);
      });
    }
  }

  getPrevious = () => {
    let { pageNo, lastPageNo } = this.state;
    if (!pageNo && lastPageNo > 1) {
      this.setState({ pageNo: --lastPageNo, lastPageNo: --lastPageNo }, () => {
        this.props.getList(this.state.pageNo);
      });
    } else if (pageNo > 1) {
      this.setState({ pageNo: --pageNo, lastPageNo: --pageNo }, () => {
        this.props.getList(this.state.pageNo);
      });
    }
  }

  change = e => {
    const value     = e.target.value;
    const oldPageNo = this.state.pageNo;
    const pageCount = this.state.pageCount;
    if (!value) {
      this.setState({
        pageNo : ''
      });
    } else if (!isNaN(value)) {
      if (+value <= 0) {
        const newPageNo = 1;
        this.setState({
          pageNo     : newPageNo,
          lastPageNo : newPageNo
        });
        if (oldPageNo !== newPageNo) {
          this.props.getList(newPageNo);
        }
      } else if (+value <= pageCount) {
        const newPageNo = +value;
        this.setState({
          pageNo     : newPageNo,
          lastPageNo : newPageNo
        });
        if (oldPageNo !== newPageNo) {
          this.props.getList(newPageNo);
        }
      } else {
        const newPageNo = pageCount;
        this.setState({
          pageNo     : newPageNo,
          lastPageNo : newPageNo
        });
        if (oldPageNo !== newPageNo) {
          this.props.getList(newPageNo);
        }
      }
    }
  }


  render() {

    const { pageNo, pageCount, lastPageNo } = this.state;

    if (pageCount > 1) {
      return (
        <div className="Q-table-footer">
          <div className='Q-table-page-panel'>
            <div className="Q-table-page-info">
              <p>{Translations.texts.page}</p>
              <input type="number" name="pageNo" value={pageNo} onChange={this.change} className="Q-table-page-number" />
              {
                Translations.texts.of === '-ից'
                ?
                <p className="Q-page-count"><span>{pageCount}</span>{Translations.texts.of}</p>
                :
                <p className="Q-page-count">{Translations.texts.of} <span>{pageCount}</span></p>
              }
            </div>
            <div className='Q-table-page-slide'>
              <div onClick={this.getPrevious} className={`${lastPageNo === 1 ? 'Q-table-not-allow-slide' : ''}`}>
                <img src={lastPageNo === 1 ? DisableLeftArrow : LeftArrow} alt={"Left"} />
              </div>
              <div onClick={this.getNext} className={`${lastPageNo === pageCount ? ' Q-table-not-allow-slide' : ''}`}>
                <img src={lastPageNo === pageCount ? DisableRightArrow : RightArrow} alt={"Left"} />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }

  }
}

export default Pagination;