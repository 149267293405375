import React, { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import ClickOutside from '../click-outside';

import './style.scss';

class Modal extends PureComponent {

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.node),
    ]),
    className: PropTypes.string,
    onClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    children: null,
    className: '',
  }

  state = {
    closed: false,
  }

  componentDidMount() { document.body.style.overflow = 'hidden'; }
  componentWillUnmount() { document.body.style.overflow = 'initial'; }

  render() {
    const { children, className, onClose } = this.props;

    return createPortal(
      <div className={`Q-modal-wrapper${className ? ` ${className}` : ''}`}>
        <ClickOutside onClickOutside={onClose}>
          <div className="Q-modal-content">
            <span className="Q-close-icon" onClick={onClose}>&times;</span>
            {children}
          </div>
        </ClickOutside>
      </div>, document.getElementById('modals'),
    );
  }
}

export default Modal;