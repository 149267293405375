import React, { memo } from 'react';
import Translations from '../../../../platform/services/translations';

import PresidentImage from '../../../../assets/images/president.jpg';

import './style.scss';

const President = memo(() => <>
  <h3 className="Q-title">
    <span className="Q-G-title-tringle" /> {Translations.texts.president}
  </h3>
  <img src={PresidentImage} alt="president" className="Q-president-image" />
  <div className="Q-president-content">
    <h4 className="Q-content-title">{Translations.texts.president_name}</h4>
    <h4>21 Հոկ 1957թ.</h4>
    <h4 className="Q-content-title">{Translations.texts.education}</h4>
    {Translations.texts.president_education.map((item, index) => <h4 key={index}>
      <span>{item.date}</span>
      {item.about}
    </h4>)}
    <h4 className="Q-content-title">{Translations.texts.working_experiance}</h4>
    {Translations.texts.president_working_experiance.map((item, index) => <h4 key={index}>
      <span>{item.date}</span>
      {item.about}
    </h4>)}
    <h4 className="Q-content-title">{Translations.texts.publications}</h4>
    {Translations.texts.president_publications.map((item, index) => <h4 key={index}>
      {item.name}
    </h4>)}
  </div>
</>);

export default President;