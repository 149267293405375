import ConnectionV2 from "../services/connectionV2";

const controllerName = 'media';

class MediaController {

  static getList = async body => {
    const result = await ConnectionV2.GET(controllerName, '', body);
    return result;
  }
};

export default MediaController;