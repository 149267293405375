import * as nodeFetch from 'node-fetch';

import Settings from '../services/settings';
import { LanguageEnum } from '../constants/enums';

class Connection {

  static BaseUrl = 'https://api.cfoa.am';

  static queryFromObject = obj => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p) && (obj[p] || obj[p] === 0)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  static createHeaders = (isUpload, server) => {
    if (server) return { language: LanguageEnum.English };

    const HEADERS = new Headers();
    !isUpload && HEADERS.append('Content-Type', 'application/json');
    HEADERS.append('language', Settings.Language); 
    return HEADERS;
  }

  static AbortAll = () => window.pendingRequests.forEach(item => item.abort());
  static responseRestructure = response => response.ok ? response.json() : {};
  static stringifyUrlEncoded = obj => {
    let urlEncoded = '';
    for (let key in obj) {
      urlEncoded += `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}&`;
    }
    return urlEncoded;
  }

  static POST = async (controllerName, actionName, body, queryConfig) => {
    const abort = new AbortController();
    const onlyQuery = !actionName && queryConfig;
    const HEADERS = Connection.createHeaders();
    window.pendingRequests.push(abort);
    window.dispatchEvent(new CustomEvent('loaderstatechange', { detail: true }));

    const response = await fetch(`${Connection.BaseUrl}/api/${controllerName}${!onlyQuery && actionName ? '/' : ''}${actionName}${queryConfig ? `?${Connection.queryFromObject(queryConfig)}` : ''}`, {
      body: JSON.stringify(body),
      method: 'POST',
      headers: HEADERS,
    });

    window.dispatchEvent(new CustomEvent('loaderstatechange', { detail: false }));
    window.pendingRequests.splice(window.pendingRequests.indexOf(abort), 1);
    return Connection.responseRestructure(response);
  }

  static PUT = async (controllerName, actionName, body, queryConfig) => {
    const abort = new AbortController();
    const onlyQuery = !actionName && queryConfig;
    const HEADERS = Connection.createHeaders();
    window.pendingRequests.push(abort);
    window.dispatchEvent(new CustomEvent('loaderstatechange', { detail: true }));

    const response = await fetch(`${Connection.BaseUrl}/api/${controllerName}${!onlyQuery && actionName ? '/' : ''}${actionName}${queryConfig ? `?${Connection.queryFromObject(queryConfig)}` : ''}`, {
      body: JSON.stringify(body),
      method: 'PUT',
      headers: HEADERS,
    })

    window.dispatchEvent(new CustomEvent('loaderstatechange', { detail: false }));
    window.pendingRequests.splice(window.pendingRequests.indexOf(abort), 1);
    return Connection.responseRestructure(response);
  }

  static DELETE = async (controllerName, actionName, queryConfig) => {
    const abort = new AbortController();
    const onlyQuery = !actionName && queryConfig;
    const HEADERS = Connection.createHeaders();
    window.pendingRequests.push(abort);    
    window.dispatchEvent(new CustomEvent('loaderstatechange', { detail: true }));
    
    const response = await fetch(`${Connection.BaseUrl}/api/${controllerName}${!onlyQuery && actionName ? '/' : ''}${actionName}${queryConfig ? `?${Connection.queryFromObject(queryConfig)}` : ''}`, {
      method: 'DELETE',
      headers: HEADERS,
    });

    window.dispatchEvent(new CustomEvent('loaderstatechange', { detail: false }));
    window.pendingRequests.splice(window.pendingRequests.indexOf(abort), 1);
    return Connection.responseRestructure(response);
  }

  static GET = async (controllerName, actionName, queryConfig) => {
    const abort = new AbortController();
    const onlyQuery = !actionName && queryConfig;
    const HEADERS = Connection.createHeaders();
    window.pendingRequests.push(abort);
    window.dispatchEvent(new CustomEvent('loaderstatechange', { detail: true }));

    const response = await fetch(`${Connection.BaseUrl}/api/${controllerName}${!onlyQuery && actionName ? '/' : ''}${actionName}${queryConfig ? `?${Connection.queryFromObject(queryConfig)}` : ''}`, {
      method: 'GET',
      headers: HEADERS,
    });

    window.dispatchEvent(new CustomEvent('loaderstatechange', { detail: false }));
    window.pendingRequests.splice(window.pendingRequests.indexOf(abort), 1);
    return Connection.responseRestructure(response);
  }

  static UPLOAD = async (controllerName, actionName, body, queryConfig) => {
    const abort = new AbortController();
    const onlyQuery = !actionName && queryConfig;
    const HEADERS = Connection.createHeaders(true);
    window.pendingRequests.push(abort);
    window.dispatchEvent(new CustomEvent('loaderstatechange', { detail: true }));

    const response = await fetch(`${Connection.BaseUrl}/api/${controllerName}${!onlyQuery && actionName ? '/' : ''}${actionName}${queryConfig ? `?${Connection.queryFromObject(queryConfig)}` : ''}`, {
      body,
      method: 'POST',
      headers: HEADERS,
    })
    
    window.dispatchEvent(new CustomEvent('loaderstatechange', { detail: false }));
    window.pendingRequests.splice(window.pendingRequests.indexOf(abort), 1);    
    return Connection.responseRestructure(response);
  }

  static ServerRequest = async ({ method, controllerName, actionName, body, queryConfig }) => {
    const onlyQuery = !actionName && queryConfig;
    const HEADERS = Connection.createHeaders(false, true);

    const response = await nodeFetch(`${Connection.BaseUrl}/api/${controllerName}${!onlyQuery ? '/' : ''}${actionName}${queryConfig ? `?${Connection.queryFromObject(queryConfig)}` : ''}`, {
      body,
      method,
      headers: HEADERS,
    });

    return response.ok ? response.json() : {};
  }
}

export default Connection;