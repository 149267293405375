import Translations from '../../../platform/services/translations'; 
import ROUTES from '../../../platform/constants/routes';

const data = () => [
  {
    title: Translations.texts.association,
    children: [
      {
        title: Translations.texts.establishment,
        route: ROUTES.ABOUT_US.MAIN,
      },
      {
        title: Translations.texts.mission,
        route: ROUTES.ABOUT_US.MISSION,
      },
      {
        title: Translations.texts.vision,
        route: ROUTES.ABOUT_US.VISION,
      },
      {
        title: Translations.texts.structure,
        route: ROUTES.ABOUT_US.STRUCTURE,
      }
    ],
  },
  {
    title: Translations.texts.president,
    route: ROUTES.ABOUT_US.PRESIDENT,
  },
  {
    title: Translations.texts.administration,
    children: [
      {
        title: Translations.texts.members,
        route: ROUTES.ABOUT_US.MEMBERS,
      },
      {
        title: Translations.texts.department_members,
        route: ROUTES.ABOUT_US.DEPARTMENT_MEMBERS,
      },
      {
        title: Translations.texts.honorable_members,
        route: ROUTES.ABOUT_US.HONORABLE_MEMBERS,
      },
      {
        title: Translations.texts.committees,
        route: ROUTES.ABOUT_US.COMMITTEES,
      },
    ],
  },
  {
    title: Translations.texts.our_team,
    route: ROUTES.ABOUT_US.OUR_TEAM,
  },
];

export default data;