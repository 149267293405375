import React, {Component, Fragment} from 'react';

import Translations from '../../../../platform/services/translations';

import './style.scss';

import DownArrow from '../../../../assets/images/arrow_down.svg';
import UpArrow from '../../../../assets/images/arrow_up.svg';
import MainController from '../../../../platform/api';


class OurTeam extends Component {

	state = {
		open: [],
		data: [],
	};

	toggleOpen = (index) => {
		let {open} = this.state;
		open[index] = !open[index];
		this.setState({open});
	};

	async componentDidMount() {
		const result = await MainController.UserItemAll();
		this.setState({ data: result.data, open: Array(result.data.length).fill(false) });
	}


	render() {
		const { data, open } = this.state;

		return (
			<Fragment>
				<h3 className="Q-title"><span className="Q-G-title-tringle"/> {Translations.texts.our_team}</h3>
				{data.map((item, index) => <div key={index} className="Q-member-item">
					<div className="Q-image" style={{background: `url("${item.image}") center/cover`}}></div>
					<div className="Q-content Q-G-pre-wrap">
						<h4 className="Q-title">{item.name}</h4>
						<h5>{item.profession}</h5>
						{
							open[index]
							?
							<div>{item.description}</div>
							:
							null
						}
						<div className="Q-button-more">
							<button className="Q-more-href" onClick={() => this.toggleOpen(index)}>
								<div className="Q-show-more">
									<img src={ open[index] ? UpArrow : DownArrow} alt={open[0] ? "Hide" : "Show"}/>
									<h6>{open[index] ? Translations.texts.close_text : Translations.texts.seemore}</h6>
								</div>
							</button>
						</div>
					</div>
				</div>)}
			</Fragment>
		)
	}
}

export default OurTeam;