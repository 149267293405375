import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import ROUTES from '../../platform/constants/routes';
import Translations from '../../platform/services/translations';
import Settings from '../../platform/services/settings';
import Search from './components/search';
import { LinkedinSocialLink, TwitterSocialLink, FacebookSocialLink, TabletScreen } from '../../platform/constants';
import { LanguageEnum } from '../../platform/constants/enums';

import LogoImage from '../../assets/images/logo.png';
import PhoneImage from '../../assets/images/phone.png';
import LocationImage from '../../assets/images/location.png';
import EmailImage from '../../assets/images/email.png';
import LinkedinImage from '../../assets/images/linkedin.png';
import TwitterImage from '../../assets/images/twitter.png';
import FacebookImage from '../../assets/images/facebook.png';
import SearchImage from '../../assets/images/search.png';
import MenuImage from '../../assets/images/menu.png';
import CloseImage from '../../assets/images/close.png';

import AmFlag from '../../assets/images/hy.png';
import EnFlag from '../../assets/images/en.png';


import { headerSize } from '../../assets/styles/variables.scss';

import './style.scss';

class Header extends PureComponent {

  state = {
    isClosed: false,
    isTablet: false,
    isSearchOpen: false,
    isMobileContentOpen: false,
  }

  componentDidMount() {
    this.checkForScreens();
    window.addEventListener('scroll', this.scroll);
		window.addEventListener('resize', this.checkForScreens);
    window.routerHistory.listen(() => {
      const { isSearchOpen } = this.state;
      isSearchOpen && this.setState({ isSearchOpen: false });
    });
    this.scroll();
  }

	componentWillUnmount() { window.removeEventListener('resize', this.checkForScreens); }

	checkForScreens = () => {
    const isTablet = window.innerWidth < TabletScreen;
    let { isMobileContentOpen } = this.state;

    if (!isTablet && isMobileContentOpen) isMobileContentOpen = false;
    
    this.setState({ isTablet, isMobileContentOpen });
  }

  scroll = () => {
    const { isClosed } = this.state;
    const size = +headerSize.replace('px', '');
    const scrolledUp = window.scrollY < this.lastScrollPosition;

    if (window.scrollY <= size * 3) this.setState({ isClosed: false });
    else if (!scrolledUp && window.scrollY > size && !isClosed) this.setState({ isClosed: true });

    this.lastScrollPosition = window.scrollY;
  }
  
  lenguageSwitcher = lang => Settings.Language = lang;
  toggleSearch = () => {
    const { isSearchOpen } = this.state;
    this.setState({ isSearchOpen: !isSearchOpen });
  };

  mobileCloseClick = () => {
    document.body.style.overflow = 'initial';  
    this.setState({ isSearchOpen: false, isMobileContentOpen: false });
  }

  openMobileMenu = () => {
    document.body.style.overflow = 'hidden';
    this.setState({ isMobileContentOpen: true });
  }

  Bottom = () => {
    const { isSearchOpen, isTablet } = this.state;

    return (
      <div className={`Q-bottom ${isTablet ? 'Q-bottom-mobile-content' : ''}`}>
        <NavLink  onClick={this.mobileCloseClick} exact to={ROUTES.HOME}>{Translations.texts.home}</NavLink>
        <NavLink onClick={this.mobileCloseClick} to={ROUTES.ABOUT_US.MAIN}>{Translations.texts.about_us}</NavLink>
        <div className="Q-activity">
          <NavLink onClick={this.mobileCloseClick} to={ROUTES.ACTIVITY}>{Translations.texts.activity}</NavLink>
          <div className="Q-dropdown">
            <NavLink onClick={this.mobileCloseClick} to={ROUTES.ACTIVITY} exact>{Translations.texts.programs}</NavLink>
            <NavLink onClick={this.mobileCloseClick} to={ROUTES.NEWS} exact>{Translations.texts.news}</NavLink>
            <NavLink onClick={this.mobileCloseClick} to={ROUTES.EVENTS} exact>{Translations.texts.events}</NavLink>
          </div>
        </div>
        <NavLink onClick={this.mobileCloseClick} to={ROUTES.LIBRARY}>{Translations.texts.library}</NavLink>
        <NavLink onClick={this.mobileCloseClick} to={ROUTES.MEDIA}>{Translations.texts.media}</NavLink>
        <NavLink onClick={this.mobileCloseClick} to={ROUTES.CONTACT_US}>{Translations.texts.contact_us}</NavLink>
        {!isTablet && <img
          src={isSearchOpen ? CloseImage : SearchImage}
          alt="search"
          onClick={this.toggleSearch}
          className={isSearchOpen ? 'Q-small-icon' : ''}
        />}
        <h5
            className={Settings.Language === LanguageEnum.Armenian ? "Q-G-simple-switcher Q-selected" : "Q-G-simple-switcher"}
            onClick={() => this.lenguageSwitcher(LanguageEnum.Armenian)}
        ><img className="Q-lang-flags" title="Armenian" alt="Armenian" src={AmFlag}/></h5>
        <h5
          className={Settings.Language === LanguageEnum.English ? "Q-G-simple-switcher Q-selected" : "Q-G-simple-switcher"}
          onClick={() => this.lenguageSwitcher(LanguageEnum.English)}
        ><img className="Q-lang-flags" title="English" alt="English" src={EnFlag}/></h5>

      </div>
    );
  }

  render() {
    const { isClosed, isTablet, isSearchOpen, isMobileContentOpen } = this.state;

    return <>
      <header className={isClosed || isTablet ? 'Q-closed' : ''}>
        <Link to={ROUTES.HOME} className="Q-logo"><img src={LogoImage} alt="logo" /></Link>
        <div className="Q-content">
          {!isTablet && <div className="Q-top">
            <h3>
              <img src={PhoneImage} alt="phone" />
              (011) 531-397
            </h3>
            <h3>
              <img src={LocationImage} alt="location" />
              {Translations.texts.office_address}
            </h3>
            <h3>
              <img src={EmailImage} alt="email" />
              info@cfoamail.am
            </h3>
            <div className="Q-social">
              <a href={LinkedinSocialLink} target="_blank" rel="noopener noreferrer"><img src={LinkedinImage} alt="linkedin" /></a>
              <a href={TwitterSocialLink} target="_blank" rel="noopener noreferrer"><img src={TwitterImage} alt="twitter" /></a>
              <a href={FacebookSocialLink} target="_blank" rel="noopener noreferrer"><img src={FacebookImage} alt="facebook" /></a>
            </div>
          </div>}
          {!isTablet ? <this.Bottom /> : <div className="Q-bottom Q-bottom-tablet">
            {isSearchOpen || isMobileContentOpen ? <img
              src={CloseImage}
              alt="close"
              className="Q-small-icon"
              onClick={this.mobileCloseClick}
            /> : <>
              <img
                src={SearchImage}
                alt="search"
                onClick={this.toggleSearch}
                className={isSearchOpen ? 'Q-small-icon' : ''}
              />
              <img
                src={MenuImage}
                alt="menu"
                className="Q-menu-icon Q-small-icon"
                onClick={this.openMobileMenu}
              />
            </>}
          </div>}
        </div>
        <Search isOpen={isSearchOpen} />
        {isMobileContentOpen && <this.Bottom />}        
      </header>
    </>;
  }
};

export default Header;