import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';

import ROUTES from '../../../../platform/constants/routes';
import ProgramController from '../../../../platform/api/programm';
import Translations from '../../../../platform/services/translations';
import Settings from '../../../../platform/services/settings';
import Modal from '../../../../components/modal';

import CalendarIcon from '../../../../assets/images/calendar.png';

import './style.scss';
import { LanguageEnum } from '../../../../platform/constants/enums';

class Details extends PureComponent {

  state = {
    data: null,
    details: null,

    confirmed: false
  };

  componentDidMount() {
    this.fetchData();
    this.historyUnlisten = window.routerHistory.listen(() => setTimeout(this.fetchData, 0));
  }

  componentWillUnmount() { this.historyUnlisten(); }

  fetchData = async () => {
    const { id } = this.props.match.params;
    const result = await ProgramController.details(id);

    if (!result || !result.success) window.routerHistory.push(ROUTES.HOME);
    else this.setState({ data: result.data });
  }

  formatDate = (date, withHours) => {
    if (!date) return 'N/A';
    const localDate = new Date(date);
    const months = Translations.texts.months;
  
    const onlyHours = localDate.getHours() < 10 ? `0${localDate.getHours()}` : localDate.getHours();
    const onlyMinutes = localDate.getMinutes() < 10 ? `0${localDate.getMinutes()}` : localDate.getMinutes();
  
    const hours = `${onlyHours}:${onlyMinutes}`;
  
    return `${localDate.getDate()} ${months[localDate.getMonth()]} ${localDate.getFullYear()}${withHours ? ` | ${hours}` : ''}`;
  }

  navigateToReports = (id) => {
    sessionStorage.setItem('newsCachedState', JSON.stringify({
      filter: {
        search: Settings.Language === LanguageEnum.Armenian ? '#Հաշվետվություն' : '#Report'
      },
      page: 1,
    }));
    window.routerHistory.push(`${ROUTES.NEWS}?program=${id}`);
  }

  render() {
    const { data, details } = this.state;
    return (
      <section className="Q-event-details">
        {data ? <>
          <div className="Q-cover" style={{ background: `url("${data.cover}") center/cover` }} />
          <div className="Q-wrapper">
            
            <div className="Q-content">
              <h3>{data.title}</h3>
              <h5>
                <img src={CalendarIcon} alt="calendar" />
                <span>{data.start_date && data.end_date
                  ?
                  `${this.formatDate(data.start_date)} - ${this.formatDate(data.end_date)}`
                  :
                  this.formatDate(data.start_date)}
                </span>
              </h5>
               <div className="Q-program-image-block">
                <img src={data.image} alt='Program'/>
              </div>
              <div className="Q-htmlBlock" dangerouslySetInnerHTML={{ __html: data.body }} />
            </div>

            {(data.hasNews || data.hasEvents) && <div className="Q-program-events-news">
              <h4>{Translations.texts.other}</h4>
              <div>
                {data.hasNews && <Link to={`${ROUTES.NEWS}?program=${data.id}`}>{Translations.texts.navigate_to_news}</Link>}
                {data.hasEvents && <Link to={`${ROUTES.EVENTS}?program=${data.id}`}>{Translations.texts.navigate_to_events}</Link>}
                {data.hasEvents && <p onClick={() => this.navigateToReports(data.id)}>{Translations.texts.navigate_to_reports}</p>}
              </div>
            </div>}
          </div>
          {details && <Modal onClose={() => this.setState({ details: null })}>
            <div className="Q-G-media-details-modal" style={{ background: `url("${details.image}") center/cover` }}  />
          </Modal>}
        </> : null}
      </section>
    );
  }
};

export default withRouter(Details);