import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import Translations from '../../../../platform/services/translations';
import ROUTES from '../../../../platform/constants/routes';

import LocationIcon from '../../../../assets/images/maps-and-flags.png' 
import CalendarIcon from '../../../../assets/images/calendar.png'

import './style.scss';

const Book = memo(({ item }) => {
  return (
    <Link to={ROUTES.EVENTS_DETAILS.replace(':id', item.id)} className="Q-events-item">
      <div className="Q-cover">
        <div style={{ background: `url("${item.image}") center/cover` }} />
      </div>
      <div className="Q-details">
        <h4>{item.name}</h4>
        <p>{getFormatted(item.description)}</p>
        <div>
          <img src={CalendarIcon} alt="calendar" />
          <p>{formatDate(item.start_date)}</p>
          <img src={LocationIcon} alt="calendar" />
          <p>{item.place}</p>
        </div>
      </div>
    </Link>
  );
});

export const formatDate = (date, withHours) => {
  if (!date) return 'N/A';
  const localDate = new Date(date);
  const months = Translations.texts.months;

  const onlyHours = localDate.getHours() < 10 ? `0${localDate.getHours()}` : localDate.getHours();
  const onlyMinutes = localDate.getMinutes() < 10 ? `0${localDate.getMinutes()}` : localDate.getMinutes();

  const hours = `${onlyHours}:${onlyMinutes}`;

  return `${localDate.getDate()} ${months[localDate.getMonth()]} ${localDate.getFullYear()}${withHours ? ` | ${hours}` : ''}`;
}

function getFormatted (description) {
	let formatted = description.split('').slice(0, 300).join('');
	if ([':', '...'].includes(formatted[formatted.length - 1])) {
		return formatted;
	} else if ([',', '`', '.'].includes(formatted[formatted.length - 1])) {
		return formatted.substr(0, formatted.length - 1) + '...'
	} else {
		return formatted + '...'
	}
}

export default Book;