import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import ROUTES from '../../../../platform/constants/routes';
import Translations from '../../../../platform/services/translations';

import './style.scss';

const PagesShortDetails = memo(() => (
  <div className="Q-home-pages-short-details">
    <Link to={ROUTES.NEWSPAPER} className="Q-details">
      <i className="icon-ic_news" />
      <h3>{Translations.texts.newspaper}</h3>
      <p>{Translations.texts.home_newspaper_short_description}</p>
      {/*<button className="Q-G-radius-button">{Translations.texts.read}</button>*/}
    </Link>
    <Link to={ROUTES.MEDIA} className="Q-details">
      <i className="icon-ic_media" />
      <h3>{Translations.texts.media}</h3>
      <p>{Translations.texts.home_media_short_description}</p>
      {/*<button className="Q-G-radius-button">{Translations.texts.read}</button>*/}
    </Link>
    <Link to={ROUTES.LIBRARY} className="Q-details">
      <i className="icon-ic_library" />
      <h3>{Translations.texts.library}</h3>
      <p>{Translations.texts.home_library_short_description}</p>
      {/*<button className="Q-G-radius-button">{Translations.texts.read}</button>*/}
    </Link>
  </div>
));

export default PagesShortDetails;