import React, { memo } from 'react';
import Translations from '../../../../platform/services/translations';

import './style.scss';

function forceDownload(){
  let url = Translations.texts.structure_img_big;
  let fileName = 'structure.png';
  let xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function(){
    let urlCreator = window.URL || window.webkitURL;
    let imageUrl = urlCreator.createObjectURL(this.response);
    let tag = document.createElement('a');
    tag.href = imageUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.send();
}

const Structure = memo(() => <>
  <h3 className="Q-title">
    <span className="Q-G-title-tringle" /> {Translations.texts.structure}
  </h3>
  <div style={{ background: `url("${Translations.texts.structure_img_big}") center/contain no-repeat` }} className="Q-Structure"></div>

  <div className="Q-download-section">
    {/* eslint-disable-next-line no-undef */}
    <button onClick={forceDownload} className="Q-download-button" type="button">{Translations.texts.download} </button>
  </div>
</>);

export default Structure;