import ConnectionV2 from '../services/connectionV2';

const controllerName = 'news';

class NewsController {

  static getList = async body => {
    const result = await ConnectionV2.POST(controllerName, 'list', body);
    return result;
  };

  static details = async id => {
    const result = await ConnectionV2.GET(controllerName, 'details', {id});
    return result;
  }

  static serverDetails = async id => {
    const result = await ConnectionV2.ServerRequest({
      method: 'GET',
      controllerName,
      actionName: 'details',
      queryConfig: { id },
    });

    return result;
  }

  static meta = async id => {
    const result = await ConnectionV2.ServerRequest({
      method: 'GET',
      controllerName,
      actionName: 'meta',
      queryConfig: { id },
    });

    return result;
  }
};

export default NewsController;