import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import Slider from 'react-slick';

import Translations from '../../../../platform/services/translations';
import ROUTES from '../../../../platform/constants/routes';
import {SmallComputerScreen, PhoneScreen} from '../../../../platform/constants';

import HomeApis from '../../../../platform/api/home';

import './style.scss';

class News extends Component {
    state = {
        data: [],
        itemsCount: 4,
    }

    componentDidMount() {
        this.getNewList();
        this.checkForScreens();
        window.addEventListener('resize', this.checkForScreens);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.checkForScreens);
    }

    checkForScreens = () => {
        const {itemsCount} = this.state;
        if (window.innerWidth < PhoneScreen) itemsCount !== 1 && this.setState({itemsCount: 1});
        else if (window.innerWidth < SmallComputerScreen) itemsCount !== 2 && this.setState({itemsCount: 2});
        else itemsCount !== 4 && this.setState({itemsCount: 4});
    }

    getNewList = async () => {
        const result = await HomeApis.getNews();
        if (result && result.success) {
            this.setState({data: result.data});
        }
    }

    render() {
        const {data, height, itemsCount} = this.state;

        return (
            <div className="Q-home-news">
                <h2 className="Q-G-block-title">{Translations.texts.news_and_posts}</h2>
                {
                    data.length
                        ?
                        <Slider infinite slidesToShow={data.length > itemsCount ? itemsCount : data.length}
                                slidesToScroll={itemsCount} dots arrows={false}>
                            {
                                data.map(item =>
                                    (
                                        <div key={item.id} className="Q-news-posts-item-block" style={{height}}>
                                            <div className="Q-cover-block"
                                                 style={{background: `url(${item.image}) center/cover`}}/>
                                            <div className="Q-details-block">
                                                <div className="Q-G-date-block-top">
                                                    <h2>{new Date(item.date).getDate()}</h2>
                                                    <h5>{Translations.texts.months_long[new Date(item.date).getMonth()]}</h5>
                                                </div>
                                                <div className="Q-news-content-block">
                                                    <h4>{splitThis(item.name,80)}</h4>
                                                    <p>{splitThis(item.description,170)}</p>
                                                </div>

                                                <Link
                                                    to={ROUTES.NEWS_DETAILS.replace(':id', item.id)}
                                                    className="Q-G-radius-button Q-G-ghost-button-block">
                                                    {Translations.texts.read}
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                )
                            }
                        </Slider>
                        :
                        ''
                }
            </div>
        )
    }
}

function splitThis(string, value) {
    let formattedString = string.substr(0, value);

    if ([':', '...'].includes(formattedString[formattedString.length - 1])) {
        return string;
    } else if ([',', '`', '.'].includes(formattedString[formattedString.length - 1])) {
        return formattedString.substr(0, formattedString.length - 1) + '...'
    } else if (string.length <= formattedString.length) {
        return string;
    } else {
        return formattedString + '...'
    }
}

export default withRouter(News);