import React from 'react';
import Structure_img from '../images/structure_am_big.png';

export default {
    media: "Մեդիա",
    home: "Գլխավոր",
    search: "Որոնում",
    library: "Գրադարան",
    about_us: "Մեր մասին",
    contact_us: "Հետադարձ կապ",
    activity: "Գործունեություն",
    other: "Այլ",
    navigate_to_news: "Դիտել նորությունները",
    navigate_to_events: "Դիտել միջոցառումները",
    navigate_to_reports: "Դիտել հաշվետվությունները",
    office_address: "0070, Երևան, Երվանդ Քոչարի 20",
    home_media_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit amet lacus et felis pulvinar sodales id a massa. Proin vulputate rutrum turpis, in molestie odio. Cras vel ex sit amet justo hendrerit imperdiet posuere ac orci. Mauris sollicitudin scelerisque risus laoreet dictum. Etiam sodales justo ut lacinia commodo. Integer.",
    home_about_us_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit amet lacus et felis pulvinar sodales id a massa. Proin vulputate rutrum turpis, in molestie odio. Cras vel ex sit amet justo hendrerit imperdiet posuere ac orci. Mauris sollicitudin scelerisque risus laoreet dictum. Etiam sodales justo ut lacinia commodo. Integer.",
    slider_title: <>&laquo;Քաղաքացիների ձայնն ու գործողությունները Հայաստանի խոշորացրած Համայնքներում&raquo;</>,
    slider_subtitle: <>Հունվարի 25-ին &laquo;Գյումրի Տեխնոլոգիական կենտրոնում&raquo; տեղի ունեցավ &laquo;Քաղաքացիների
        ձայնն ու գործողությունները Հայաստանի խոշորացված Համայնքներում&raquo; ծրագրի պաշտոնական մեկնարկի միջոցառումը</>,
    newspaper: "Թերթ",
    news_and_posts: "Նորություններ, Հոդվածներ",
    contact_form_text: "Մենք ուրախ կլինենք օգնել և պատասխանել Ձեր յուրաքանչյուր հարցին:",
    name: "Անուն",
    email: "Էլ․փոստ",
    message: "Հաղորդագրություն",
    send: "Ուղարկել",
    read: "Կարդալ",
    association: "Միավորում",
    establishment: "Հիմնադրում",
    mission: "Առաքելություն",
    vision: "Տեսլական",
    structure: "Կառուցվածք",
    structure_img_big: Structure_img,
    president: "Նախագահ",
    administration: "Վարչություն",
    members: "Անդամներ",
    committees: "Հանձնաժողովներ",
    address: "Հասցե",
    addressValue: "Երևան, Երվանդ Քոչարի 20",
    phone: "Հեռախոս",
    state: "Մարզ",
    community: "Համայնք",
    name_surname: "Անուն, Ազգանուն",
    surname: "Ազգանուն",
    regulations: "Կանոնադրություն",
    establishment_title: "ՀԻՄՆԱԴՐՈՒՄ",
    establishment_content: <>1996թ-ի վերջում Հայաստանի Հանրապետությունում սկսվեց տեղական ինքնակառավարման համակարգի ձևավորումը: Դրան աջակցելու նպատակով 1997-1999թթ. Քաղաքների կառավարման միջազգային ասոցիացիան (ICMA), ՀՀ կառավարման դպրոցի հետ համատեղ, Հայաստանում իրականացնում էր «Տեղական ֆինանսների կառավարման արդիականացում» ծրագիրը: Ծրագրի մասնակիցներն էին համայնքների ղեկավարների տեղակալներ և ֆինանսական ծառայությունների ղեկավարներ: Ծրագրի նպատակն էր մասնակիցներին ուսուցանել տեղական բյուջետավարման հմտություններ, նրանց հաղորդել համապատասխան գիտելիքներ և ծանոթացնել միջազգային փորձին:<br/><br/> Ծրագիրը ունեցավ մեծ հաջողություն, և վերջինիս դասախոսական կազմն ու մասնակիցները, գտնելով, որ տեղական ինքնակառավարման համակարգի կայացման ու զարգացման համար մեծ նշանակություն ունի հասարակական կազմակերպությունների ստեղծումը, 1998թ. սեպտեմբերի 24-ին հրավիրեցին հիմնադիր ժողով և ստեղծեցին «Համայնքների ֆինանսիստների միավորում» հասարակական կազմակերպությունը:</>,
    mission_title: "ԱՌԱՔԵԼՈՒԹՅՈՒՆԸ",
    mission_content: "ՀՖՄ առաքելությունն է՝ իր մասնագիտական գործունեությամբ նպաստել ՀՀ տեղական ինքնակառավարման համակարգի ամրապնդմանն ու զարգացմանը՝ վեր հանելով բնագավառում առկա հիմնախնդիրներն ու մարտահրավերները և մասնակցելով, մի կողմից, համայնքների ֆինանսական կառավարման բնագավառի զարգացման քաղաքականությունների, օրենսդրական կարգավարումների և մեթոդաբանական գործիքների մշակմանը, նրանց համապատասխանեցմանը ժամանակակից մեթոդներին ու միջազգային չափանիշներին և, մյուս կողմից, հետամուտ լինելով համայնքների ֆինանսական կառավարման բնագավառում առկա քաղաքականությունների, օրենսդրական պահանջների և հայեցակարգային մոտեցումների կիրառմանը և գործնականում իրագործմանը, համայնքների ֆինանսական ծառայությունների հզորացմանը և ֆինանսական ծառայողների դերի բարձրացմանը:",
    vision_title: "ՏԵՍԼԱԿԱՆ",
    vision_content: "Համայնքների ֆինանսիստների միավորումը ՀՀ տեղական ինքնակառավարման համակարգի «մտքի» առանցքային կենտրոններից է՝ զինված գործունեության համար անհրաժեշտ ռեսուրսներով, տեղեկատվական և հաղորդակցության ժամանակակից համակարգերով, համալրված արհեստավարժ փորձագետներով ու մասնագետներով, և լայն ճանաչում ստացած Հայաստանում և արտերկրում: Կազմակերպությունը մասնագիտական բազմակողմանի քննարկումների ու երկխոսության մի հարթակ է տեղական ինքնակառավարման բնագավառում քաղաքականություն ձևավորող և իրականացնող պետական կառավարման մարմինների, տեղական ինքնակառավարման մարմինների, քաղաքացիական հասարակության և մասնավոր հատվածի սուբյեկտների համար՝ ի նպաստ ՀՀ տեղական ինքնակառավարման համակարգի ամրապնդման և զարգացման:",
    photoarchive: "Լուսանկարներ",
    videoarchive: "Տեսանյութեր",
    education: "Կրթություն",
    working_experiance: "Աշխատանքային Փորձ",
    president_name: "Վահան Մովսիսյան",
    president_education: [{
        date: '1974-1979',
        about: 'Երևանի պոլիտեխնիկական ինստիտուտ, ինժեներ-շինարար',
    },
        {
            date: '1981-1985',
            about: 'Մոսկվայի Տնտեսագիտական Ինստիտուտ, տնտեսագետ – մունիցիպալ  տնտեսության կառավարման և պլանավորման ոլորտում',
        },
        {
            date: '1997-1998',
            about: 'Ֆինանսական կառավարման արդիականացում՚ (ICMA, USAID) կուրսեր',
        }],
    president_working_experiance: [{
        date: '1998-ից',
        about: '«Համայնքների ֆինանսիստների միավորում» ՀԿ, Նախագահ',
    },
        {
            date: '2018-ից',
            about: '«Քաղաքացիների ձայնն ու գործողությունները Հայաստանի  խոշորացված համայնքներում» ծրագիր /Եվրամիություն/, Ծրագրի ղեկավար',
        },
        {
            date: '2014-ից',
            about: '«Հանրային մասնակցություն տեղական ինքնակառավարմանը» ծրագիր /ԱՄՆ ՄԶԳ/ Ծրագրի ղեկավար',
        },
        {
            date: '2014-2019',
            about: '«Համայնքներում արդյունքների վրա հիմնված բյուջետավարման ներդրում» ծրագիր /ԳՄՀԸ/, Ծրագրի ղեկավար',
        },
        {
            date: '2017-2018',
            about: '«Բազմաբնակավայր համայնքներում տարեկան աշխատանքային պլանների մշակում» ծրագիր /ԳՄՀԸ/, Ծրագրի ղեկավար',
        },
        {
            date: '2016-2017',
            about: '«Տեղական ֆինանսների համեմատական վերլուծություն Հայաստանում» ծրագիր /Եվրոպայի խորհուրդ/, տեղական փորձագետ',
        },
        {
            date: '2015-2016',
            about: '«Տարածքային ծրագրերի մշակում Տաջիկիստանում» /ՄԱԶԾ Տաջիկիստան/, Միջազգային խորհրդատու',
        },
        {
            date: '2013-2014',
            about: '«Բյուջետային թափանցիկության և ֆինանսական հաշվետվողականության ընդլայնումը Երևանի հանրակրթության համակարգում» ծրագիր /Բաց հասարակության հիմնադրամներ/, Ծրագրի ղեկավար',
        },
        {
            date: '2008-2014',
            about: '«Պետական և տեղական մակարդակներում ռազմավարական պլանավորման և ֆյուջետավարման արդիական համակարգի ներդրումը Տաջիկիստանում» ծրագիր  /ՄԱԶԾ Տաջիկիստան/, Միջազգային խորհրդատու',
        },
        {
            date: '2012-2013',
            about: '«Տեղական սոցիալական պլանավորում և սոցիալական ծառայությունների համագործակցության ինստիտուցիոնալացումը Հայաստանում» ծրագիր /Յունիսեֆ/, Փորձագետ',
        },
        {
            date: '2011',
            about: '«Որոշումների մասնակցային կայացման և պլանավորման՝ ՏԻՄ երի կարողությունների գնահատում» ծրագիր /ՄԱԶԾ/, Փորձագետ',
        },
        {
            date: '2010-2011',
            about: '«Երևանի բյուջեի մոնիթորինգ» ծրագիր /Բաց հասարակության հիմնադրամներ/,  Ծրագրի ղեկավար',
        },
        {
            date: '2006-2008',
            about: '«Հազարամյակի մարտահրավերներ՚ ծրագրով իրականացվող ճանապարհաշինարարական աշխաըանքների մոնիտորինգ» /Բաց հասարակության հիմնադրամներ/,  Ծրագրի ղեկավար',
        },
        {
            date: '2005-2006',
            about: '«Ֆինանսական համահարթեցման մեխանիզմների զարգացումը Հայաստանում» ծրագիր / Բաց հասարակության հիմնադրամներ/, Ծրագրի ղեկավար',
        },
        {
            date: '2004-2005',
            about: '«Գյուղական ենթակառուցվածքների ուսումնասիրություն» հետազոտություններ /Համաշխարային բանկ/, Ծրագրի ղեկավար',
        },
        {
            date: '2000-2001',
            about: '«ՀՀ համայնքներում տեղեկատվական-վերլուծական կենտրոնների ստեղծման աջակցություն» /Եվրասիա համագործակցություն հիմնադրամ/ Ծրագրի ղեկավար',
        },
        {
            date: '1997-1999',
            about: 'Աշտարակի Քաղաքապետի խորհրդական',
        },
        {
            date: '1994-1996',
            about: 'Աշտարակի Քաղխորհրդի նախագահի առաջին տեղակալ',
        },
        {
            date: '1991-1996',
            about: 'Աշտարակի Քաղխորհրդի պատգամավոր, Բյուջետային հանձնաժողովի նախագահ',
        }],
    president_publications: [
        {name: '«ՀՀ տեղական ինքնակառավարման տարեկան գնահատման (ՏԻ տարեկան  ինդեքսի հաշվարկման) մեթոդաբանություն», Երևան, 2017, համահեղինակ'},
        {name: '«Համայնքի ավագանու նիստի օրակարգում հարցը ընդգրկելու նախաձեռնության կազմակերպման և իրականացման» ուղեցույց, Երևան, 2017, համահեղինակ'},
        {name: '«ՀՀ տեղական ինքնակառավարման համակարգում արդյունքահեն բյուջետավարման մեթոդաբանություն», Երևան, 2015, համահեղինակ'},
        {name: '«Ֆինանսական համահարթեցման մեխանիզմների զարգացումը Հայաստանում», Երևան, 2007, հեղինակ'},
        {name: '«Ըստ արդյունքների բյուջետավարումը ՀՀ տեղական ինքնակառավարման համակարգում», ուսումնական ձեռնարկ, Երևան, 2006, հեղինակ'},
        {name: '«Տեղական Ինքնակառավարման հիմունքներ», ուսումնական ձեռնարկ, Երևան, 1999, հեղինակ'},
        {name: '«Համայնքային բյուջեների կառավարում» մեթոդական ձեռնարկ, Երևան, 1998, հեղինակ'},
    ],
    president_languages: [{
        name: 'Հայերեն',
        type: 'մայրենի',
    },
        {
            name: 'Ռուսերեն',
            type: 'գերազանց',
        },
        {
            name: 'Անգլերեն ',
            type: 'բավարար',
        }],
    department_members: "Վարչության անդամներ",
    department_members_list: ['Վահրամ Շահբազյան', 'Նորա Ղուկասյան', 'Աբրահամ Արտաշեսյան', 'Սեյրան Մարկոսյան', 'Տիգրան Բրսիկյան', 'Գոհար Դավթյան', 'Կարեն Հովհաննեսյան', 'Լևոն Թումանյան', 'Ռուբեն Հայրապետյան', 'Դավիթ Մարգարյան', 'Քնարիկ Այվազովա', 'Արծրունի Իգիթյան', 'Աննա Չոբանյան'],
    honorable_members: "Պատվավոր անդամներ",
    members_content: <>
        <h4 className="Q-members-title">«ՀԱՄԱՅՆՔՆԵՐԻ ՖԻՆԱՆՍԻՍՏՆԵՐԻ ՄԻԱՎՈՐՈՒՄ»<br/>
            ՀԿ 9-ՐԴ ՀԱՄԱԺՈՂՈՎԻ<br/>
            ՈՐՈՇՈՒՄ<br/>
            ՄՈՒՏՔԻ ՎՃԱՐ ԵՎ ԱՆԴԱՄԱՎՃԱՐԻ ՉԱՓԵՐ ՍԱՀՄԱՆԵԼՈՒ ՄԱՍԻՆ
        </h4>
        <p>
            11 հուլիսի 2017թ.<br/><br/>
            Հիմք ընդունելով «Համայնքների ֆինանսիստների միավորում» հասարակական կազմակերպության
            Կանոնադրության 6.4 և 6.7 կետերը, ՀՖՄ 9-րդ համաժողովը որոշում է հաստատել ՀՖՄ մուտքի վճարի և
            անդամավճարի չափերը:
        </p>
        <h4 className="Q-members-title">1. Սահմանել կազմակերպությանն անդամակցելու մուտքի վճարը հետևյալ չափերով.</h4>
        <p>
            ա) ֆիզիկական անձանց համար՝ 1 000 (մեկ հազար) ՀՀ դրամ.<br/>
            բ) համայնքների համար՝ 10 000 (տասը հազար) ՀՀ դրամ։
        </p>
        <h4 className="Q-members-title">2. Սահմանել կազմակերպության տարեկան անդամավճարը հետևյալ չափերով.</h4>
        <p>
            ա) ֆիզիկական անձանց համար՝ 3 000 (երեք հազար) ՀՀ դրամ.<br/>
            բ) համայնքների համար՝<br/><br/>
            &ensp;- մինչև 10 հազար բնակչություն ունեցող՝ 20 000 (քսան հազար) ՀՀ դրամ.<br/>
            &ensp;- 10 – 20 հազար բնակչություն ունեցող՝ 40 000 (քառասուն հազար) ՀՀ դրամ.<br/>
            &ensp;- 20 – 30 հազար բնակչություն ունեցող՝ 60 000 (վաթսուն հազար) ՀՀ դրամ.<br/>
            &ensp;- 30 – 40 հազար բնակչություն ունեցող՝ 80 000 (ութսուն հազար) ՀՀ դրամ.<br/>
            &ensp;- 40 – 50 հազար բնակչություն ունեցող՝ 100 000 (հարյուր հազար) ՀՀ դրամ.<br/>
            &ensp;- 50 – 200 հազար բնակչություն ունեցող՝ 150 000 (հարյուր հիսուն հազար) ՀՀ դրամ.<br/>
            &ensp;- 200 հազարից ավելի բնակչություն ունեցող՝ 250 000 (երկու հարյուր հիսուն հազար) ՀՀ դրամ։
        </p>
        <h4 className="Q-members-title">Եթե ցանկանում եք դառնալ ՀՖՄ ՀԿ անդամ</h4>
    </>,
    apply: "Դիմել",
    members_list: "Համայնքների Ֆինանսիստների միավորման անդամների ցուցակ",
    artyusha: <>
        <h4 className="Q-title">Արտյուշա Մուրադյան</h4>
        <h5>1949-2006 (հետմահու)</h5>
        <p>
            (ՀՖՄ վարչության անդամ <span>13.05.2008թ.</span> նիստի որոշմամբ)<br/>
            <span>1998 թ-ից</span> ՀՖՄ հիմնադիր անդամ,<br/>
            <span>1998–2006 թթ.</span> ՀՖՄ վարչության անդամ
        </p>
    </>,
    rudik: <>
        <h4 className="Q-title">Ռուդիկ Հարոյան</h4>
        <h5>1948–2013</h5>
        <p>
            (ՀՖՄ վարչության անդամ <span>27.04.2010թ.</span> նիստի որոշմամբ)<br/>
            <span>1998 թ-ից</span> ՀՖՄ հիմնադիր անդամ,<br/>
            <span>1998–2006 թթ.</span> ՀՖՄ վարչության անդամ
        </p>
    </>,
    david: <>
        <h4 className="Q-title">Դավիթ Թումանյան</h4>
        <h5>1963–2016 (հետմահու) </h5>
        <p>
            (ՀՖՄ վարչության անդամ <span>23.12.2016թ. </span> նիստի որոշմամբ)<br/>
            <span>1998 թ-ից</span> ՀՖՄ հիմնադիր անդամ,<br/>
            <span>2005–2016 թթ.</span> ՀՖՄ վարչության անդամ<br/>
            <span>2005–2016 թթ.</span> ՀՖՄ փոխնախագահ <br/>
        </p>
    </>,
    volodya: <><h4 className="Q-title">Վոլոդյա Վասիլյան</h4>
        <h5>ծնվ. 1946</h5>
        <p>
            (ՀՖՄ վարչության անդամ<span>27.04.2010թ.</span> նիստի որոշմամբ)<br/>
            <span>1998 թ-ից</span> ՀՖՄ հիմնադիր անդամ,<br/>
            <span>1998–2006 թթ.</span> ՀՖՄ վարչության անդամ
        </p>
    </>,


    our_team_hasmik: <>
        <h4 className="Q-title">Հասմիկ Մանասյան (Գրիգորյան)</h4>
        <h5>Տեղական ինքնակառավարման և ֆինանսական կառավարման փորձագետ</h5>
        <span id="more">2018 թվականից  աշխատում է որպես ֆինանսական կառավարման և տեղական
             ինքնակառավարման փորձագետ։<br/>
             Մասնակցել է ՀՖՄ փորձագիտական թիմի կողմից իրականացվող ծրագրային բյուջեների,
              տարեկան աշխատանքային
              պլանների մեթոդաբանության կազմման աշխատանքներին։<br/>
              2018 թվականից համահեղինակ է ՀՖՄ
              փորձագիտական թիմի կողմից մշակվող  «Տեղական ինքնակառավարումը Հայաստանում» և «ՀՀ տեղական
              ինքնակառավարման տարեկան ինդեքս» գրքերի։ <br/> <br/>
                Հասմիկն ունի բակալավրի և մագիստրոսի աստիճան։ Ավարտել է
            ՀՀ պետական կառավարման ակադեմիան(ՀՀՊԿԱ) հանրային ֆինանսների կառավարում բաժինը 2015թ․։<br/>
             Հետաքրքրված է էկոտուրիզմով, զբաղվում է ՀՀ-ում ագրոտուրիզմի  զարգացմամբ։</span>
    </>,

    our_team_hasmik_short: <>
        <h4 className="Q-title">Հասմիկ Մանասյան (Գրիգորյան)</h4>
        <h5>Տեղական ինքնակառավարման և ֆինանսական կառավարման փորձագետ</h5>
        <span id="more">2018 թվականից  աշխատում է որպես ֆինանսական կառավարման և տեղական
             ինքնակառավարման փորձագետ։<br/>
             Մասնակցել է ՀՖՄ փորձագիտական թիմի կողմից իրականացվող ծրագրային բյուջեների,
              տարեկան աշխատանքային
              պլանների մեթոդաբանության կազմման աշխատանքներին։<br/>
              2018 թվականից համահեղինակ է ՀՖՄ
              փորձագիտական թիմի կողմից մշակվող  «Տեղական ինքնակառավարումը Հայաստանում» և «ՀՀ տեղական
              ինքնակառավարման տարեկան ինդեքս» գրքերի։ <br/>
        </span>
    </>,

    our_team_karen: <>
        <h4 className="Q-title">Կարեն Հովհաննեսյան</h4>
        <h5>Փորձագետ</h5>

        <p>
            Կարեն Հովհաննեսյանը ՀՖՄ թիմին միացել է 2016 թվականից և ունի տեղական ինքնակառավարման բնագավառում շուրջ 20
            տարվա աշխատանքային փորձ:<br/> Տեղական ինքնակառավարման ոլորտում Կարենը աշխատանքը սկսել է դեռևս 1999 թվականից Սիսիանի
            համայնքապետարանում, որտեղ զբաղեցնելով տարբեր պաշտոններ ղեկավարել է համայնքի զարգացմանն ուղղված մի շարք
            ծրագրեր:<br/>
        </p>
        <p>
            2012 թվականից, որպես Սիսիանի փոխքաղաքապետ համակարգել է միջազգային կազմակերպությունների և Սիսիանի քույր
            քաղաքների հետ համագործակցությանն ուղղված աշխատանքները:<br/> <br/>
            Կարենն ավարտել է Երևանի պետական համալսարանի Փիլիսոփայության, սոցիոլոգիայի և հոգեբանության ֆակուլտետը,
            մասնակցել է մասնագիտական վերապատրաստման տարբեր ծրագրերի:<br/> <br/>
            2019թ. Կարեն Հովհաննեսյանը ընտրվել է Գորիսի պետական համալսարանի կառավարման խորհրդի նախագահ:
        </p>
    </>,

    our_team_karen_short: <>
        <h4 className="Q-title">Կարեն Հովհաննեսյան</h4>
        <h5>Փորձագետ</h5>

        <p>
            Կարեն Հովհաննեսյանը ՀՖՄ թիմին միացել է 2016 թվականից և ունի տեղական ինքնակառավարման բնագավառում շուրջ 20
            տարվա աշխատանքային փորձ:<br/> Տեղական ինքնակառավարման ոլորտում Կարենը աշխատանքը սկսել է դեռևս 1999 թվականից Սիսիանի
            համայնքապետարանում, որտեղ զբաղեցնելով տարբեր պաշտոններ ղեկավարել է համայնքի զարգացմանն ուղղված մի շարք
            ծրագրեր:<br/>
        </p>
    </>,


    our_team_anna: <>
        <h4 className="Q-title">Աննա Ղորուխչյան</h4>
        <h5>Տեղական ինքնակառավարման փորձագետ</h5>
        <p>
            Աննա Ղորուխչյանը միացել է ՀՖՄ թիմին 2016թ-ին՝ որպես կամավոր։ 2018թ-ից աշխատում է ԱՄՆՄԶԳ աջակցությամբ
            իրականացվող«ՀաՄաՏեղ» ծրագրի շրջանակներում՝ որպես տեղական ինքնակառավարման և ֆինանսական կառավարման
            փորձագետ։Ներգրավված է եղել ԳՄՀԸ կողմից իրականացվող բազմաբնակավայր համայնքների ծրագրային բյուջեների, տարեկան
            աշխատանքային պլանների կազմման աշխատանքներում։<br/> <br/>
            Աննան ունի բակալավրի և մագիստրոսի աստիճան «Պետական և մունիցիպալ կառավարում» մասնագիտությամբ։ Ավարտել է
            Հայաստանի պետական տնտեսագիտական համալսարանի Կառավարման ֆակուլտետը։ «Էրազմուս+» ծրագրի շրջանակներում սովորել
            է Հունգարիայի Միշկոլցի համալսարանի Տնտեսագիտության ֆակուլտետում։<br/> <br/>
            Մասնակցել է մի շարք ազգային և միջազգային դասընթացների, այդ թվում՝ «Երիտասարդ առաջնորդներ. տեղական
            ժողովրդավարության ապագան» սեմինար (Եվրոպայի խորհուրդ), «Հանդուրժողականության ուսուցում անգլերենով» ամառային
            ճամբար (Թուրքիայում ԱՄՆ դեսպանատուն), «Եվրոպական միությունը և Կովկասը 2030թ.` կանխատեսելով ապագան»
            աշխատաժողով (Թյուբինգենի համալսարան)։<br/> <br/>
            Բացի տեղական ինքնակառավարման ոլորտից Աննան հետաքրքրված է նաև բարձրագույն կրթության կառավարման հիմնահարցերով։
            
        </p>
    </>,

    our_team_anna_short: <>
        <h4 className="Q-title">Աննա Ղորուխչյան</h4>
        <h5>Տեղական ինքնակառավարման փորձագետ</h5>
        <p>
            Աննա Ղորուխչյանը միացել է ՀՖՄ թիմին 2016թ-ին՝ որպես կամավոր։ 2018թ-ից աշխատում է ԱՄՆՄԶԳ աջակցությամբ
            իրականացվող«ՀաՄաՏեղ» ծրագրի շրջանակներում՝ որպես տեղական ինքնակառավարման և ֆինանսական կառավարման
            փորձագետ։Ներգրավվածէ եղել ԳՄՀԸ կողմից իրականացվող բազմաբնակավայր համայնքների ծրագրային բյուջեների, տարեկան
            աշխատանքային պլանների կազմման աշխատանքներում։<br/>
        </p>
    </>,
    our_team_kristine: <>
        <h4 className="Q-title">Քրիստինե Ատոմյան</h4>
        <h5>Գրասենյակի կառավարիչ</h5>
        <p>
            Քրիստինե Ատոմյանը «Համայնքների ֆինանսիստների միավորում» ՀԿ անդամ է և ՀՖՄ թիմում է արդեն 18 տարի՝ 2001
            թվականից:<br/> <br/>
            Երկար տարիների ընթացքում նա եղել է գործավար-գանձապահը՝ ապահովելով կազմակերպության ամենօրյա վարչական և
            ադմինիստրատիվ աշխատանքը, իրականացրել է տարբեր ծրագրերի ասիստենտի գործառույթները ևս, իսկ 2014թ-ից Քրիստինեն
            զբաղեցնում է ՀՖՄ գրասենյակի կառավարչի պաշտոնը։<br/> <br/>
            Քրիստինեն սովորել է Աշտարակի թիվ 5 ռուսական դպրոցում, ավարտել է Երևանի Պետական Համալսարանի Ինֆորմատիկայի և
            կիրառական մաթեմատիկայի ֆակուլտետը:
        </p>
    </>,

    our_team_kristine_short: <>
        <h4 className="Q-title">Քրիստինե Ատոմյան</h4>
        <h5>Գրասենյակի կառավարիչ</h5>
        <p>
            Քրիստինե Ատոմյանը «Համայնքների ֆինանսիստների միավորում» ՀԿ անդամ է և ՀՖՄ թիմում է արդեն 18 տարի՝ 2001
            թվականից:<br/>
            Երկար տարիների ընթացքում նա եղել է գործավար-գանձապահը՝ ապահովելով կազմակերպության ամենօրյա վարչական և
            ադմինիստրատիվ աշխատանքը, իրականացրել է տարբեր ծրագրերի ասիստենտի գործառույթները ևս, իսկ 2014 թ-ից Քրիստինեն
            զբաղեցնում է ՀՖՄ գրասենյակի կառավարչի պաշտոնը։<br/>
        </p>
    </>,

    download: "Ներբեռնել",
    programs: 'Ծրագրեր',
    all: 'Բոլորը',
    active: 'Ընթացիկ',
    publications: 'Հրատարակություններ',
    languages: 'Լեզուներ',
    finished: 'Ավարտված',
    months: ['Հուն', 'Փետ', 'Մրտ', 'Ապր', 'Մայ', 'Հնս', 'Հլս', 'Օգս', 'Սպտ', 'Հկտ', 'Նոյ', 'Դկտ'],
    months_long: ['Հունվար', 'Փետրվար', 'Մարտ', 'Ապրիլ', 'Մայիս', 'Հունիս', 'Հուլիս', 'Օգոստոս', 'Սեպտեմբեր', 'Հոկտեմբեր', 'Նոյեմբեր', 'Դեկտեմբեր'],
    foundation: "Foundation",
    foundation_text: <>Ծանոթացել եմ &laquo;Համայնքների ֆինանսիստների միավորում&raquo; հասարակական կազմակերպության
        կանոնադրությանը: Ցանկանում եմ դառնալ այդ միավորման անդամ:</>,
    download_file: "Ներբեռնել ֆայլը",
    objectives: "Խնդրիներ",
    committees_subtitle: "Համայնքների Ֆինանսիստների Միավորման Վարչությունը ընդգրկում է 3 հանձնաժողով՝",
    committees_content: [{
        title: 'Կազմակերպչական, տեղեկատվության և հասարակության հետ կապերի հանձնաժողով',
        president: "Սեյրան Մարկոսյան",
        members: ["Նորա Ղուկասյան", "Ալիսա Միրումյան", "Լևոն  Թումանյան"],
        objectives: [
            "Միավորման անդամության դիմումների և անդամությունից զրկելու առաջարկությունների ներկայացում վարչությանը ",
            "Միավորման անդամների կողմից անդամավճարների մուծումների վերահսկում",
            "Միավորման սեփական(ոչ դրամաշնորհային) միջոցների մուտքերի և ծախսերի ուղղությունների վերաբերյալ առաջարկությունների նախապատրաստում ու կատարման վերահսկում ",
            "Միավորման համաժողովի նախապատրաստում ",
            "Տնտեսական ընկերությունների, մասնաճյուղերի և ներկայացուցչությունների հետ կապերի ապահովում ",
            "Ոլորտին վերաբերող որոշման նախագծերի կազմում և ներկայացում վարչությանը:",
            "Կոորդինացնել ՀՖՄ-ի կապը այլ հասարակական կազմակերպությունների և քաղաքացիական հասարակության այլ սուբյեկտների հետ ",
            " Կոորդինացնել ՀՖՄ-ի կապը ԶԼՄ-ների հետ." +
            " \n միջոցառումների ժամանակ հրավիրել համապատասխան լրատվամիջոցների ներկայացուցիչների,\n" +
            " \n ԶԼՄ-ներին տրամադրել համապատասխան տեղեկատվություն միավորման աշխատանքների և իրականացվող ծրագրերի մասին ",
            "ԶԼՄ-ների միջոցով տարածել միավորման պաշտոնական տեսակետները՝ հայտարարությունների, հարցազրույցների, վերլուծական և այլ բնույթի հոդվածների միջոցով կամ այլ եղանակներով ",
            "Միավորման կամ իրականացվող աշխատանքների մասին խեղաթյուրված տեղեկատվության առկայության դեպքում հերքում և պարզաբանումներ տալ ԶԼՄ-ներով ",
            "Միավորման ղեկավարության աշխատանքները լուսաբանող տեղեկատվությունը հասանելի դարձնել միավորման անդամներին և միավորման գործունեությամբ հետաքրքրվող այլ անձանց",
        ],
    },
        {
            title: 'Օրենսդրական, իրավական հարցերի և համայնքներին մեթոդական,  խորհրդատվական աջակցության հանձնաժողով',
            president: "Վահրամ Շահբազյան",
            members: ["Քնարիկ Այվազովա", "Արծրունի Իգիթյան", "Գոհար Դավթյան", "Աննա Չոբանյան", "Տիգրան Բրսիկյան", "Կարեն Հովհաննեսյան"],
            objectives: [
                "Տեղական ինքնակառավարման ոլորտի օրենքների ու նորմատիվ իրավական ակտերի մշակում և տեղական ինքնակառավարման մարմինների (ՏԻՄ-երի) ու հասարակության լայն շրջանակների հետ քննարկումների կազմակերպում։",
                "Օրենսդրական փոփոխությունների նախապատրաստում, դրանց առնչությամբ ՀՀ Ազգային ժողովի մշտական հանձնաժողովների, ինչպես նաև համապատասխան պետական լիազոր մարմինների հետ քննարկումների նախաձեռնում։",
                "Ընդունված օրենսդրական փաստաթղթերի մեկնաբանությունների, բացատրական ու մեթոդական ցուցումների մշակում, հրատարակում և տարածում։",
                "Տեղական ինքնակառավարման հիմնահարցերին վերաբերող կոնֆերանսների և սեմինարների կազմակերպում։",
                "Համայնքների շահերի պաշտպանություն և պետական մարմինների կողմից օրենքների կատարման վերահսկում:",
                "Տեղական ինքնակառավարման բնագավառի օրենսդրական դաշտի բարելավմանն ուղղված նախաձեռնությունների քննարկումների կազմակերպում ՀՀ համայնքներում",
                "Համայնքների աշխատակազմերի սոցիալ-տնտեսական և ֆինանսական կառավարման հարցերով զբաղվող աշխատակիցներին տեղական ինքնակառավարման բնագավառի տեղեկատվությամբ ապահովում, նրանց որակավորման և պրոֆեսիոնալիզմի բարձրացում։",
                "Խորհրդատվական և տեխնիկական աջակցության ապահովում համայնքների աշխատակազմերի աշխատակիցներին համայնքի հնգամյա զարգացման, տարեկան ծրագրերի, տարեկան աշխատանքային պլանի և բյուջեի կազմման, նրանց կատարման վերահսկման, հաշվետվությունների կազմման և այլ հարցերում։",
                "Համայնքների ֆինանսական կառավարման մասնագետների համար ուսուցման ծրագրերի, դասընթացների, սեմինարների կազմակերպում, ուսումնական և մեթոդական նյութերի մշակում և տրամադրում՝ համագործակցելով միջազգային և տեղական կազմակերպությունների հետ։",
                "Ֆինանսական կառավարման ժամանակակից մեթոդների և մեխանիզմների (արևմտյան, արևելաեվրոպական, ԱՊՀ) փորձարկում և կիրառում ՀՀ համայնքներում, դրանց արդյունավետության գնահատում։",
                "ՀՀ համայնքների միջազգային  փորձի փոխանակման ապահովում, առաջավոր փորձի ներդնում, գյուղական բնակավայրեր ունեցող համայնքների հետ աշխատանքի ակտիվացում։",
                "Համայնքների ֆինանսական և այլ ծառայողների շահերի պաշտպանություն, նրանց դերի բարձրացում։",
                "ՀՖՄ-ի կողմից համայնքներին մատուցվող ծառայությունների աստիճանական ընդլայնում։",
            ],
        }, {
            title: 'Միջազգային կապերի հանձնաժողով',
            president: "Աբրահամ Արտաշեսյան",
            members: ["Դավիթ Մարգարյան", "Ռուբեն Հայրապետյան "],
            objectives: [
                "Համագործակցության և  գործընկերության հաստատում արտերկրի տեղական ինքնակառավարման ոլորտի հասարակական կազմակերպությունների հետ",
                "Կապերի հաստատում տեղական ինքնակառավարման ոլորտի միջազգային կազմակերպությունների հետ",
                "Մասնակցություն հանրային կառավարման բարեփոխումներին, ապակենտրոնացման և տեղական ինքնակառավարման հիմնախնդիրներին նվիրված միջազգային ֆորումների, գիտաժողովների, սեմինարների",
                "Տարածաշրջանային ծրագրերի կազմում, դրանց ներկայացում դոնոր կազմակերպություններին, ծրագրերի իրականացում",
                "Տեղական ինքնակառավարման ոլորտի միջազգային փորձի ուսումնասիրություն, համայնքներին համապատասխան տեղեկատվության տրամադրում և ՀՀ-ում ներդրման հնարավորությունների որոնում",
                "Տարածաշրջանային և միջազգային կոնֆերանսների և  սեմինարների կազմակերպում",
                "Հոդվածների և գիտական աշխատանքների պատրաստում և տպագրություն տեղական և արտերկրյա մամուլում"
            ],
        },
    ],

    events: 'Միջոցառումներ',
    eventgo: 'Ես կմասնակցեմ',
    news: "Նորություններ",
    prev: "Նախորդ",
    next: "Հաջորդ",
    back: 'Վերադառնալ',
    recomended: "Առաջարկվում է",
    from: "Սկսած",
    our_team: "Մեր թիմը",
    photos: "Նկարներ",
    files: "Ֆայլեր",
    home_library_short_description: 'Գրադարան էջում կարող եք ծանոթանալ ՀՖՄ թիմի կողմից ստեղծված կամ գործունեությանը համապատասխանող գրքերին',
    home_media_short_description: 'Մեդիա բաժինը  իր մեջ ընդգրկում է բոլոր կարևոր իրադարձությունների հիշարժան պահերը նկարներով ու վիդիոհոլովակներով',
    home_newspaper_short_description: 'Այս բաժնում դուք կարող եք ծանոթանալ ՀՖՄ-ի կողմից իրականացված և իրականացվելիք միջոցառումներին, ինչպես նաև տեղական ինքնակառավարման համակարգի հիմնական զարգացումներին',
    calculator: 'Հաշվիչ',
    choose: 'Ընտրել',
    eventend: 'Ավարտված է',
    generate: 'Գեներացնել',
    seemore: "Կարդալ ավելին",
    close_text: "Փակել",
    read_ptogramm : "Դիտել ծրագիրը",
    page: "Էջ",
    of: "-ից",
    su: "Կիրակի",
    armenian: "Հայերեն",
    english: "Անգլերեն",
    cfoa_developed: "Պատրաստված է ՀՖՄ-ի կողմից",
    start_date: "Սկիզբ",
    end_date: "Վերջ",
    lang: "am"
};

