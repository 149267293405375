import React, {Component} from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';

import am from "../../../../platform/date/am";
import ProgrammController from '../../../../platform/api/programm';
import ROUTES from '../../../../platform/constants/routes';

import Translations from '../../../../platform/services/translations';
import SearchImage from '../../../../assets/images/search.png';
import CalendarIcon from '../../../../assets/images/calendar_black.png';

import './style.scss';
import { detailsTypeEnum } from '../../../../platform/constants/enums';

registerLocale("am", am);

class Filter extends Component {

    state = {
        filter: {
            search: '',
            start_date: '',
            end_date: '',   
        },
        programs: [],
        date: new Date(),
    }

    async componentDidMount() {
        const result = await ProgrammController.filterNew(detailsTypeEnum.news);
        if (result.data && result.data.length) this.setState({ programs: result.data });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (JSON.stringify(nextProps.filter) !== JSON.stringify(prevState.filter)) {
            const filter = {...nextProps.filter};
            if (filter.start_date) filter.start_date = new Date(filter.start_date);
            if (filter.end_date) filter.end_date = new Date(filter.end_date);
            return { filter };
        }
        return null;
    }

    onSearchChange = e => {
        const {filter} = this.state;
        filter.search = e.target.value;
        this.setState({filter}, () => this.props.onChange(filter));
    }

    onChange = date => this.setState({date})

    startDateChange = date => {
        const {filter} = this.state;
        filter.start_date = date;
        this.setState({filter}, () => this.props.onChange(filter));
    }

    endDateChange = date => {
        const {filter} = this.state;
        const filteredDate = date ? new Date(date.getTime() + 86399999) : null;
        filter.end_date = filteredDate;
        this.setState({filter}, () => this.props.onChange(filter));
    }

    clearProgram = () => {
        const { filter } = this.state;
        window.routerHistory.replace(ROUTES.NEWS);
        this.props.onChange(filter);
    }

    chooseProgram = id => {
        const { filter } = this.state;
        window.routerHistory.replace(`${ROUTES.NEWS}?program=${id}`);
        this.props.onChange(filter);
    }

    render() {
        const { programId } = this.props;
        const { filter, programs, } = this.state;

        return (
            <div className="Q-news-filter Q-program-filter">
                <div className="Q-search">
                    <img src={SearchImage} alt="search"/>
                    <input name="search" value={filter.search} placeholder={Translations.texts.search}
                           onChange={this.onSearchChange}/>
                </div>
                <div className="Q-date">
                    <img src={CalendarIcon} alt="calendar"/>
                    <DatePicker
                        locale={Translations.texts.lang}
                        dateFormat="dd/MM/yyyy"
                        selected={filter.start_date}
                        maxDate={filter.end_date}
                        onChange={this.startDateChange}
                        placeholderText={Translations.texts.start_date}
                    />
                    <div className="from-to">-</div>
                    <DatePicker
                        locale={Translations.texts.lang}
                        dateFormat="dd/MM/yyyy"
                        selected={filter.end_date}
                        minDate={filter.start_date}
                        onChange={this.endDateChange}
                        placeholderText={Translations.texts.end_date}
                    />
                </div>

                {!!programs.length && <>
                    <h4><br />{Translations.texts.programs}<br /><br /></h4>
                    <div className="Q-types">
                        <h4 className={!programId ? 'Q-chosen' : ''} onClick={this.clearProgram}>{Translations.texts.all}</h4>
                        {programs.map(item => <h4
                            key={item.id}
                            title={item.name}
                            className={programId === item.id ? 'Q-chosen' : ''}
                            onClick={() => this.chooseProgram(item.id)}
                        >{item.name}</h4>)}
                    </div>
                </>}
            </div>
        )
    }
};

export default Filter;