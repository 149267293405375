import React, { Component } from 'react';

import YearPicker from '../year-picker';

import './style.scss';

class Filter extends Component {

  state = {
    filter: { year: '2019' },
  }

  onYearSelect = year => {
    const { filter } = this.state;
    filter.year = year;
    this.setState({ filter }, () => this.props.onChange(filter));
  }

  render() {

    return (
      <div className="Q-newspaper-filter">
        <YearPicker onChange={this.onYearSelect} />
      </div>
    )
  }
};

export default Filter;