import React, { Component } from 'react';

import Translations from '../../../../platform/services/translations';
import Validator from '../../../../platform/services/validator';
import ContactController from '../../../../platform/api/contact';

import './style.scss';

class Form extends Component {

  state = {
    submited: false,
    form: {
      firstName: '',
      lastName: '',
      email: '',
      body: '',
    }
  }

  change = e => {
    const { form } = this.state;
    form[e.target.name] = e.target.value;
    this.setState({ form });
  }

  submit = async e => {
    e.preventDefault();
    const form = {...this.state.form};
    const { submited } = this.state;
    !submited && this.setState({ submited: true });

    if (form.firstName && form.lastName && Validator.isEmailValid(form.email) && form.body) {
      form.name = `${form.firstName} ${form.lastName}`;
      delete form.firstName;
      delete form.lastName;
      const result = await ContactController.NewMessage(form);
      if (result.status) {
        window.scrollTo(0, 0);
        this.setState({
          submited: false,
          form: {
            firstName: '',
            lastName: '',
            email: '',
            body: '',
          }
        });
      }
    }
  }

  render() {
    const { submited, form } = this.state;

    return (
      <div className="Q-contact-form">
        <h3><span className="Q-G-title-tringle" /> {Translations.texts.contact_us}</h3>

        <div className="Q-maps">
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3048.7492287206123!2d44.51458351570097!3d40.17013677852327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abc5f76865a53%3A0xae85bd9868162506!2s20+Yervand+Kochar+St%2C+Yerevan%2C+Armenia!5e0!3m2!1sen!2s!4v1554718856802!5m2!1sen!2s"
            allowFullScreen
          />
        </div>
        <form>
          <p>{Translations.texts.contact_form_text}</p>

          {/* window.innerWidth > 1024px */}
          <div className="Q-default-name-block">
            <h5 className={submited && (!form.firstName || !form.lastName) ? 'Q-G-invalid-text' : ''}>{Translations.texts.name_surname}</h5>
            <div className="Q-G-name-surname">
              <input className="Q-G-bordered-input" name="firstName" onChange={this.change} value={form.firstName} />            
              <input className="Q-G-bordered-input" name="lastName" onChange={this.change} value={form.lastName} />
            </div>
          </div>

          {/* window.innerWidth < 1024px */}
          <div className="Q-responsive-name-block">
            <h5 className={submited && !form.firstName ? 'Q-G-invalid-text' : ''}>{Translations.texts.name}</h5>
            <input className="Q-G-bordered-input" name="firstName" onChange={this.change} value={form.firstName} />
          </div>
          <div className="Q-responsive-name-block">
            <h5 className={submited && !form.lastName ? 'Q-G-invalid-text' : ''}>{Translations.texts.surname}</h5>
            <input className="Q-G-bordered-input" name="lastName" onChange={this.change} value={form.lastName} />
          </div>

          <h5 className={submited && !Validator.isEmailValid(form.email) ? 'Q-G-invalid-text' : ''}>{Translations.texts.email}</h5>
          <input className="Q-G-bordered-input" name="email" onChange={this.change} value={form.email} />
          <h5 className={submited && !form.body ? 'Q-G-invalid-text' : ''}>{Translations.texts.message}</h5>
          <input className="Q-G-bordered-input" name="body" onChange={this.change} value={form.body} />
          <button className="Q-G-radius-button" onClick={this.submit}>{Translations.texts.send}</button>
        </form>
      </div>
    )
  }
}

export default Form;