export const LanguageEnum = { 
  Armenian: 'hy',
  English: 'en',
};

export const aboutPagesEnum = {
  Establishment: 1,
  Mission: 2,
  Vision: 3,
  President: 4,
};

export const programFilterEnum = {
  active: 0,
  finished: 1,
};

export const detailsTypeEnum = {
  news: 1,
  events: 2
};

export const searchTypeEnum = {
  news: 1,
  books: 2,
  events: 3,
  programs: 4,
};

export const pageCoverTypeEnum = {
  aboutUs: 1,
  programs: 2,
  news: 3,
  events: 4,
  library: 5,
  media: 6,
  contactUs: 7,
  newspaper: 8
};