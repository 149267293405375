import React, { memo } from 'react';

import Translations from '../../../../platform/services/translations';

const Mission = memo(() => (
    <>
      <h3 className="Q-title"><span className="Q-G-title-tringle" /> {Translations.texts.mission}</h3>
      <p>{Translations.texts.mission_content}</p>
    </> 
));

export default Mission;