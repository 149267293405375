import React, { memo } from 'react';

import Translations from '../../../../platform/services/translations';

import RudikImage from '../../../../assets/images/rudik_member.jpg';
import ArrtyushaImage from '../../../../assets/images/artyusha_member.jpg';
import DefaultImage from '../../../../assets/images/member_default.jpg';

import './style.scss';

const HonorableMembers = memo(() => <>
  <h3 className="Q-title"><span className="Q-G-title-tringle" /> {Translations.texts.honorable_members}</h3>
  <div className="Q-member-item">
    <div className="Q-image" style={{ background: `url("${DefaultImage}") center/cover` }} />
    <div className="Q-content Q-honorable-content">
      {Translations.texts.volodya}
    </div>
  </div>
  <div className="Q-member-item">
    <div className="Q-image" style={{ background: `url("${RudikImage}") center/cover` }} />
    <div className="Q-content Q-honorable-content">
      {Translations.texts.rudik}
    </div>
  </div>
  <div className="Q-member-item">
    <div className="Q-image" style={{ background: `url("${ArrtyushaImage}") center/cover` }} />
    <div className="Q-content Q-honorable-content">
      {Translations.texts.artyusha}
    </div>
  </div>
  <div className="Q-member-item">
    <div className="Q-image" style={{ background: `url("${DefaultImage}") center/cover` }} />
    <div className="Q-content Q-honorable-content">
      {Translations.texts.david}
    </div>
  </div>
</>);

export default HonorableMembers;