import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ClickOutside extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.element,
    ]).isRequired,
    onClickOutside: PropTypes.func.isRequired,
  };

  componentDidMount() {
    document.addEventListener('click', this.handle, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handle, true);
  }

  handle = e => {
    const { onClickOutside } = this.props;
    !this.container.contains(e.target) && onClickOutside(e);
  };
  
  render() {
    const { children, onClickOutside, ...props } = this.props;
    return <div {...props} ref={item => this.container = item}>{children}</div>;
  }
}

export default ClickOutside;