import React, { Component } from 'react';

import Modal from '../../../../../../components/modal';
import Translations from '../../../../../../platform/services/translations';
import Validator from '../../../../../../platform/services/validator';
import MainController from '../../../../../../platform/api';

import FoundationPDF from '../../../../../../assets/pdfs/foundation.pdf';

import './style.scss';

class Apply extends Component {

  state = {
    form: {
      name: '',
      surname: '',
      email: '',
      community: '',
      state: '',
      address: '',
      phone: '',
    }
  }

  change = e => {
    const { form } = this.state;
    form[e.target.name] = e.target.value;
    this.setState({ form });
  }

  submit = async e => {
    e.preventDefault();
    const { submited, form }  = this.state;
    !submited && this.setState({ submited: true });

    if (form.name && form.surname && form.state && form.community && Validator.isEmailValid(form.email) && form.phone) {
      const body = new FormData()
      for (let key in form) body.append(key, form[key]);
      const result = await MainController.CFOAMember(body);
      result.status && this.props.onClose();
    }
  }

  render() {
    const { onClose } = this.props;
    const { submited, form } = this.state;

    return (
      <Modal onClose={onClose} className="Q-apply-modal">
        <h3>{Translations.texts.foundation}</h3>
        <p>{Translations.texts.foundation_text}</p>
        <form>
          <div>
            <h5 className={submited && (!form.name || !form.surname) ? 'Q-G-invalid-text' : ''}>{Translations.texts.name_surname}</h5>
            <div className="Q-G-name-surname">
              <input className="Q-G-bordered-input" name="name" onChange={this.change} />
              <input className="Q-G-bordered-input" name="surname" onChange={this.change} />
            </div>
          </div>
          <div>
            <h5 className={submited && !form.state ? 'Q-G-invalid-text' : ''}>{Translations.texts.state}</h5>
            <input className="Q-G-bordered-input" name="state" onChange={this.change} />
          </div>
          <div>
            <h5 className={submited && !form.community ? 'Q-G-invalid-text' : ''}>{Translations.texts.community}</h5>
            <input className="Q-G-bordered-input" name="community" onChange={this.change} />
          </div>
          <div>
            <h5 className={submited && !form.address ? 'Q-G-invalid-text' : ''}>{Translations.texts.address}</h5>
            <input className="Q-G-bordered-input" name="address" onChange={this.change} />
          </div>
          <div>
            <h5 className={submited && !form.phone ? 'Q-G-invalid-text' : ''}>{Translations.texts.phone}</h5>
            <input className="Q-G-bordered-input" name="phone" onChange={this.change} />
          </div>
          <div>
            <h5 className={submited && !Validator.isEmailValid(form.email) ? 'Q-G-invalid-text' : ''}>{Translations.texts.email}</h5>
            <input className="Q-G-bordered-input" name="email" onChange={this.change} />
          </div>
          <button className="Q-G-radius-button" onClick={this.submit}>{Translations.texts.apply}</button>
        </form>
        <a href={FoundationPDF} download>{Translations.texts.download_file}</a>
      </Modal>
    );
  }
}

export default Apply;