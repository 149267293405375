import ConnectionV2 from "../services/connectionV2";

const controllerName = 'program';

class ProgramController {

  static getList = async body => {
    if (!body.search) delete body.search;
    if (!(body.type >= 0)) delete body.type;
    const result = await ConnectionV2.POST(controllerName, 'all', body);
    return result;
  }

  static getHomeList = async () => {
    const result = await ConnectionV2.GET(controllerName, 'home');
    return result;
  }

  static filterNew = async type => {
    const result = await ConnectionV2.GET(controllerName, 'filterNew', { type });
    return result;
  }

  static details = async id => {
    const result = await ConnectionV2.GET(controllerName, 'detailsAll', {id});
    return result;
  }

  static serverDetails = async id => {
    const result = await ConnectionV2.ServerRequest({
      method: 'GET',
      controllerName,
      actionName: 'detailsAll',
      queryConfig: { id },
    });

    return result;
  }

  static meta = async id => {
    const result = await ConnectionV2.ServerRequest({
      method: 'GET',
      controllerName,
      actionName: 'meta',
      queryConfig: { id },
    });

    return result;
  }
};

export default ProgramController;