import React, { PureComponent } from 'react';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';

import Estabilishment from './components/estabilishment';
import Mission from './components/mission';
import Vission from './components/vision';
import Structure from './components/structure';
import President from './components/president';
import Members from './components/members';
import DepartmentMembers from './components/department-members';
import HonorableMembers from './components/honorable-members';
import Comittees from './components/committees';
import OurTeam from './components/our-team';

import data from './services/data';
import fileApis from '../../platform/api/file';

import Translations from '../../platform/services/translations';
import AboutUsCover from '../../assets/images/aboutus.jpg';

import './style.scss';
import { pageCoverTypeEnum } from '../../platform/constants/enums';

class AboutUs extends PureComponent {

  state = {
    cover: ''
  }

  onAccordionSelect = route => {
    window.routerHistory.push(route);
  }
  onAccordionToggle = indexArr => {
    const index = indexArr[0];
    if (index) {
      window.routerHistory.replace(data()[index].route || data()[index].children[0].route);
    } else {
      
    }
  }

  componentDidMount() {
    this.getCover();
  }

  getCover = async () => {
    const result = await fileApis.getCover(pageCoverTypeEnum.aboutUs);
    if (result && result.success) this.setState({ cover: result.data });
    else this.setState({ cover: AboutUsCover });
  }

  render() {
    const { component, openedMenu } = this.props;
    const { cover } = this.state;

    return (
      <section className="Q-about-us">
        <div style={{ background: `url("${cover}") center/cover`, width: '100%', height: '100%' }}>
          <h1 className="Q-G-page-starting">{Translations.texts.about_us}</h1>
        </div>
        <div className="Q-content">
          <Accordion allowZeroExpanded onChange={this.onAccordionToggle} preExpanded={[openedMenu]}>
            {data().map((item, index) => (
              <AccordionItem key={index} uuid={index}>
                <AccordionItemHeading>
                  <AccordionItemButton className={`Q-accardion-header ${openedMenu === index ? 'Q-choosed' : ''}`}>{item.title}{ [0, 2].includes(index) ? <i className={`${openedMenu === index ? 'icon-ic_top' : 'icon-ic_down'}`} /> : undefined}</AccordionItemButton>
                </AccordionItemHeading>
                {item.children && <AccordionItemPanel>
                  {item.children.map((sub, index) => (
                    <h4
                      key={index}
                      className={`Q-accordion-item ${window.location.pathname.includes(sub.route) ? 'Q-choosed' : ''}`}
                      onClick={() => this.onAccordionSelect(sub.route)}
                    >{sub.title}</h4>
                  ))}
                </AccordionItemPanel>}
              </AccordionItem>
            ))}
          </Accordion>
          <div className="Q-content">{component}</div>
        </div>
      </section>
    );
  }
}

const buildAboutUsPage = (openedMenu, component) => () => (
  <AboutUs
    openedMenu={openedMenu}
    component={component}
  />
);


export const AboutEstabilishment = buildAboutUsPage(0, <Estabilishment />);
export const AboutMission = buildAboutUsPage(0, <Mission />);
export const AboutVission = buildAboutUsPage(0, <Vission />);
export const AboutStructure = buildAboutUsPage(0, <Structure />);
export const AboutPresident = buildAboutUsPage(1, <President />);
export const AboutMembers = buildAboutUsPage(2, <Members />);
export const AboutDepartmentMembers = buildAboutUsPage(2, <DepartmentMembers />);
export const AboutHonorableMembers = buildAboutUsPage(2, <HonorableMembers />);
export const AboutComittees = buildAboutUsPage(2, <Comittees />);
export const AboutOurTeam = buildAboutUsPage(3, <OurTeam />);