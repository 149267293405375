import React, { Component } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { defaults } from 'chart.js';

import ROUTES from './platform/constants/routes';
import Header from './components/header';
import Home from './modules/home';

import Library from './modules/library';
import Media from './modules/media';
import ContactUs from './modules/contact-us';
import Newspaper from './modules/newspaper';
import Programm from './modules/programs';
import ProgramDetails from './modules/programs/pages/details';
import Events from './modules/events';
import News from './modules/news';
import NewsDetails from './modules/news/pages/details';
import EventDetails from './modules/events/pages/details';
import Footer from './components/footer';
import Calculator from './modules/calculator';
import PageLoader from './components/page-loader';
import InitialHeaders from './platform/constants/initial-headers';
import {
  AboutEstabilishment,
  AboutMission,
  AboutVission,
  AboutStructure,
  AboutPresident,
  AboutMembers,
  AboutDepartmentMembers,
  AboutHonorableMembers,
  AboutComittees,
  AboutOurTeam
} from './modules/about-us';

import './assets/styles/index.scss';

defaults.global.legend = defaults.global.legend || {};
defaults.global.legend.display = false;

class App extends Component {

  state = { generalAPILoaded: false };

  componentDidMount() {
    window.pendingRequests = [];
    window.routerHistory = createBrowserHistory();
    window.addEventListener('loaderstatechange', e => this.setState({ loaderActive: e.detail }));
    document.title = InitialHeaders.Title;

    //? For SSR to fully load Browser API (general for 'window')
    this.setState({ generalAPILoaded: true });
  }

  render() {
    const { generalAPILoaded, loaderActive } = this.state;

    return generalAPILoaded ? (
      <Router history={window.routerHistory}>
        <>
          <Header />
          <div className="Q-main">
            <Switch>
              <Route path={ROUTES.HOME} component={Home} exact />
              <Route path={ROUTES.LIBRARY} component={Library} exact />
              <Route path={ROUTES.MEDIA} component={Media} exact />
              <Route path={ROUTES.CONTACT_US} component={ContactUs} exact />
              <Route path={ROUTES.NEWSPAPER} component={Newspaper} exact />
              <Route path={ROUTES.ACTIVITY} component={Programm} exact />
              <Route path={ROUTES.EVENTS} component={Events} exact />
              <Route path={ROUTES.CALCULATOR} component={Calculator} exact />
              <Route path={ROUTES.NEWS} component={News} exact />
              <Route path={ROUTES.NEWS_DETAILS} component={NewsDetails} exact />
              <Route path={ROUTES.EVENTS_DETAILS} component={EventDetails} exact />
              <Route path={ROUTES.PROGRAM_DETAILS} component={ProgramDetails} exact />

              {/* About us routes */}

              <Route key="about-us-0" path={ROUTES.ABOUT_US.MAIN} component={AboutEstabilishment} exact />
              <Route key="about-us-1" path={ROUTES.ABOUT_US.MISSION} component={AboutMission} exact />
              <Route key="about-us-2" path={ROUTES.ABOUT_US.VISION} component={AboutVission} exact />
              <Route key="about-us-3" path={ROUTES.ABOUT_US.STRUCTURE} component={AboutStructure} exact />
              <Route key="about-us-4" path={ROUTES.ABOUT_US.PRESIDENT} component={AboutPresident} exact />
              <Route key="about-us-5" path={ROUTES.ABOUT_US.MEMBERS} component={AboutMembers} exact />
              <Route key="about-us-6" path={ROUTES.ABOUT_US.DEPARTMENT_MEMBERS} component={AboutDepartmentMembers} exact />
              <Route key="about-us-7" path={ROUTES.ABOUT_US.HONORABLE_MEMBERS} component={AboutHonorableMembers} exact />
              <Route key="about-us-9" path={ROUTES.ABOUT_US.COMMITTEES} component={AboutComittees} exact />
              <Route key="about-us-10" path={ROUTES.ABOUT_US.OUR_TEAM} component={AboutOurTeam} exact />

              {/* About us routes end */}

              <Redirect to={ROUTES.HOME} />
            </Switch>   
          </div>
          {loaderActive && <PageLoader />}
          <Footer />
        </>
      </Router>
    ) : null;
  }
}

export default App;
