import Connection from "../services/connection";
import ConnectionV2 from '../services/connectionV2';
import Settings from "../services/settings";

class MainController {

  static CFOAMember = async body => {
    const result = await Connection.UPLOAD('cfoa-member', '', body);
    return result;
  };

  static Search = async body => {
    const result = await ConnectionV2.GET('section', 'search', body);
    return result;
  };

  static UserItemAll = async () => {
    const result = await ConnectionV2.GET('userItem', 'all', { language: Settings.Language  });
    return result;
  };
};

export default MainController;