import React, { PureComponent } from 'react';

import './style.scss';

class PageLoader extends PureComponent {

  state = { show: false };

  loadingTimeout;

  componentDidMount() { this.loadingTimeout = window.setTimeout(() => this.setState({ show: true }), 200); }
  componentWillUnmount() { this.loadingTimeout && clearTimeout(this.loadingTimeout); }

  render() {
    const { show } = this.state;
    
    return show ? (
      <div className="Q-loader-wrapper">
        <div className="Q-loader" />
      </div>
    ) : null;
  }
}

export default PageLoader;