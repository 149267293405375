import React, { Component } from 'react';
import Slider from 'react-slick';

import './style.scss';

import newspaperApis from '../../../../platform/api/newspapers';

class YearPicker extends Component {
 
  state = {
    years: [],
    selected: '',
  }

  componentDidMount () {
    this.getYears();
  }  

  getYears = async () => {
    const result = await newspaperApis.getYears();
    if (result && result.success) {
      this.setState({ years: result.data.map(item => {
        return {
          title: `${item}`
        }
      })});
    }
  }

  onSelect = selected => this.setState({ selected }, () => this.props.onChange(selected));

  render () {
    const { years, selected } = this.state; 

    return(
      <Slider verticalSwiping vertical infinite={false} initialSlide={years.length} slidesToShow={5} slidesToScroll={5} dots arrows={false} className='Q-year-picker-carousel'>
      {years.map((year, index) => (
        <div className="Q-year-picker-carousel-item" key={index}>
          <h2 onClick={() => this.onSelect(year.title)} className={selected === year.title ? 'Q-selected' : ''}>{year.title}</h2>
        </div>
      ))}
    </Slider>
    )
  }
}

export default YearPicker;