import ConnectionV2 from "../services/connectionV2";

const controllerNameV2 = 'event';

class EventsController {

  static getList = async body => {
    const result = await ConnectionV2.POST(controllerNameV2, 'list', body);
    return result;
  }

  static details = async id => {
    const result = await ConnectionV2.GET(controllerNameV2, 'details', {id});
    return result;
  }

  static getHomeList = async body => {
    const result = await ConnectionV2.GET(controllerNameV2, 'home');
    return result;
  }

  static participate = async(body) => {
    await ConnectionV2.POST(controllerNameV2, 'part?id=' + body.id);
  }

  static serverDetails = async id => {
    const result = await ConnectionV2.ServerRequest({
      method: 'GET',
      controllerName: controllerNameV2,
      actionName: 'details',
      queryConfig: { id },
    });

    return result;
  }

  static meta = async id => {
    const result = await ConnectionV2.ServerRequest({
      method: 'GET',
      controllerName: controllerNameV2,
      actionName: 'meta',
      queryConfig: { id },
    });

    return result;
  }
};

export default EventsController;