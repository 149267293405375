import ConnectionV2 from "../services/connectionV2";

const controllerNameV2 = 'newspaper';

class NewspapersController {

  static getList = async body => {
    const result = await ConnectionV2.GET(controllerNameV2, 'list', body);
    return result;
  }

  static addView = async id => {
    const result = await ConnectionV2.POST(controllerNameV2, `action?id=${id}&action=${2}`);
    return result;
  }

  static addDownload = async id => {
    const result = await ConnectionV2.POST(controllerNameV2, `action?id=${id}&action=${1}`);
    return result;
  }

  static getYears = async () => {
    const result = await ConnectionV2.GET(controllerNameV2, `year`);
    return result;
  }
};

export default NewspapersController;