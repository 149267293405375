import React, {Fragment, Component} from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel
} from 'react-accessible-accordion';

import Plus from '../../../../assets/images/plus.svg';
import Minus from '../../../../assets/images/minus.svg';

import Translations from '../../../../platform/services/translations';

import './style.scss';


const imagesPath = {
    minus: Minus,
    plus: Plus
}


class Committees extends Component {

    state = {
       open: [false, false, false]
    };

    toggleImage = (index) => {
        let{ open } = this.state;
        if (!open[index]) {
            open = open.map((item, itemIndex) => {
                return itemIndex === index ? !open[index]: false
            });
        } else {
            open[index] = !open[index];
        }
        this.setState({ open });
    };

    getImageName= (index) => {
        const { open } = this.state;
        return open[index] ? 'minus' : 'plus';
    }

    render() {
        return (
            <Fragment>
                <h3 className="Q-title"><span className="Q-G-title-tringle"/> {Translations.texts.committees}</h3>
                <h4 className="Q-committees-subtitle">{Translations.texts.committees_subtitle}</h4>
                <Accordion className="Q-committees-accordion" allowZeroExpanded>
                    {Translations.texts.committees_content.map((item, index) => (
                        <AccordionItem onClick={() => this.toggleImage(index)} key={index}>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <img className="Q-G-switch" src={imagesPath[this.getImageName(index)]}
                                         alt="Switch"/>
                                    <span className="Q-com-title">  {item.title}</span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <h4>{Translations.texts.president}</h4>
                                <p>{item.president}</p>
                                <h4>{Translations.texts.members}</h4>
                                <p>{item.members.map((item, index) => <Fragment key={index}>{item}<br/></Fragment>)}</p>
                                <h4>{Translations.texts.objectives}</h4>
                                <ul>
                                    {item.objectives.map((item, index) => <li key={index}>{item}</li>)}
                                </ul>
                            </AccordionItemPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            </Fragment>
        );
    }


}

export default Committees;