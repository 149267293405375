import React, { PureComponent } from 'react';

import Filter from './components/filter';
import ManageItem from './components/Manage';

import Translations from '../../platform/services/translations';

import newsApis from '../../platform/api/news';
import fileApis from '../../platform/api/file';

// import { scrolledToBottom } from '../../platform/services/helper';

import newsCover from '../../assets/images/news.jpg'

import './style.scss';
// import Connection from '../../platform/services/connection';
import { pageCoverTypeEnum } from '../../platform/constants/enums';

import Pagination from '../../components/pagination';

class News extends PureComponent {

  state = {
    data     : [],
    filter   : {},
    page     : 1,
    perPage  : 10,
    pageCount: 1,
    loading  : false,
    cover    : ''
  }

  componentDidMount = async() => {
    try {
      const cachedState = sessionStorage.getItem('newsCachedState');
      if (cachedState) this.setState(JSON.parse(cachedState));
    } catch (e) {}

    setTimeout(() => {
      this.getList();
      this.getCover();
    }, 0);
    // window.addEventListener('scroll', this.onScroll);
  }

  getProgram = () => {
    const query = new URLSearchParams(window.location.search);
    const programId = query.get('program');
    return programId;
  }

  getList = async () => {
    // if (overwrite) Connection.AbortAll();
    const { page, perPage } = this.state;
    const filter = {...this.state.filter};
    if (filter.start_date) filter.start_date = filter.start_date.valueOf();
    if (filter.end_date) filter.end_date = filter.end_date.valueOf();
    const query = new URLSearchParams(window.location.search);
    const programId = query.get('program');
    if (programId) filter.programId = programId;
    this.setState({ loading: true }, async () => {
      const result = await newsApis.getList({ page, perPage, ...filter });
      if (result && result.success) {
        this.setState({
          data: result.data.itemList,
          pageCount: result.data.pageCount,
          loading: false
        });
      } else if (result && !result.success) {
        sessionStorage.removeItem('newsCachedState');
        window.location.reload();
      }
    });
  }

  getCover = async() => {
    const result = await fileApis.getCover(pageCoverTypeEnum.news);
    if (result && result.success) this.setState({ cover: result.data });
    else this.setState({ cover: newsCover });
  }

  // componentWillUnmount () {
  //   window.removeEventListener('scroll', this.onScroll);
  // }

  // onScroll = () => {
  //   if (scrolledToBottom() && this.state.page < this.state.pageCount && !this.state.loading) {
  //     this.setState({ page: this.state.page + 1 }, async () => {
  //       this.getList();
  //     })
  //   }
  // }

  // onFilterChange = filter => this.setState({ filter }, () => this.getList(true));
  onFilterChange = filter => {
    this.setState({ filter, pageCount: 0, page: 1 }, () => this.getList())
  }
  
  getListByPageNo = (page) => {
		this.setState({page: page}, () => {
			this.getList();
		});
  }
  
  componentWillUnmount() {
    const { filter, page } = this.state;

    sessionStorage.setItem('newsCachedState', JSON.stringify({
      filter,
      page,
    }));
  }

  componentDidCatch() { sessionStorage.removeItem('newsCachedState'); }

  render() {
    const { data, cover, page, pageCount, filter } = this.state;

    return (
      <section className="Q-news">
        <div style={{ background: `url("${cover}") center/cover`, width: '100%', height: '100%' }}>
          <h1 className="Q-G-page-starting">{Translations.texts.news}</h1>
        </div>
        <div className="Q-content">
          <Filter onChange={this.onFilterChange} programId={this.getProgram()} filter={filter} />
          <div className="Q-list">
            {
              data.map((item, index) => <ManageItem key={index} item={item}/>)
            }
            <Pagination pageNo={page} lastPageNo={pageCount} pageCount={pageCount} getList={this.getListByPageNo}/>
          </div>
        </div>
      </section>
    );
  }
}

export default News;