import React, { PureComponent } from 'react';

import MediaController from '../../platform/api/media';
import FileApis from '../../platform/api/file';

import Modal from '../../components/modal';

import Translations from '../../platform/services/translations';
import { scrolledToBottom } from '../../platform/services/helper';
import Connection from '../../platform/services/connection';

import MediaCover from '../../assets/images/media.jpg';
import PlayIcon from '../../assets/images/play.png';

import './style.scss';
import { pageCoverTypeEnum } from '../../platform/constants/enums';

const typeEnum = {
  Photo: 1,
  Video: 2,
};

class Media extends PureComponent {

  state = {
    type: typeEnum.Photo,
    details: null,
    detailsIndex: null,
    page: 1,
    perPage: 20,
    pageCount: 1,
    data: [],
    loading: false,
    cover: ''
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('keyup', this.checkKeypress);
    this.getList();
    this.getCover();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('keyup', this.checkKeypress);  
  }

  getList = async (overwrite, successCallback) => {
    if (overwrite) Connection.AbortAll();
    const { data, page, perPage, type } = this.state;
    this.setState({ loading: true }, async () => {
      const result = await MediaController.getList({ perPage, page, type });
      if (result && result.success) {
        this.setState({
          data: overwrite ? result.data.itemList : [...data, ...result.data.itemList],
          pageCount: result.pageCount,
          loading: false,
        }, () => successCallback && successCallback());
      }
    });
  }

  getCover = async() => {
    const result = await FileApis.getCover(pageCoverTypeEnum.media);
    if (result && result.success) this.setState({ cover: result.data });
    else this.setState({ cover: MediaCover });
  }

  changeType = type => this.setState({ type, page: 1 }, () => this.getList(true));

  onScroll = () => {
    if (scrolledToBottom() && this.state.page !== this.state.pageCount && !this.state.loading) {
      this.setState({ page: this.state.page + 1 }, async () => {
        this.getList();
      })
    }
  }

  checkKeypress = e => {
    const { details, data, detailsIndex, page, pageCount } = this.state;

    if (details) {
      if (e.keyCode === 37 && data[detailsIndex - 1]) this.prev();
      if (e.keyCode === 39 && (data[detailsIndex + 1] || page < pageCount)) this.next();
    }
  }

  prev = () => {
    const { detailsIndex, data } = this.state;
    this.setState({ detailsIndex: detailsIndex - 1, details: data[detailsIndex - 1] });
  }

  next = () => {
    const { detailsIndex, data, page, pageCount } = this.state;

    if (!data[detailsIndex + 1] && page < pageCount) {
      this.setState({ page: page + 1 }, async () => {
        this.getList(false, () => {
          const { data } = this.state;
          this.setState({ detailsIndex: detailsIndex + 1, details: data[detailsIndex + 1] });
        });
      })
    } else if (data[detailsIndex + 1]) this.setState({ detailsIndex: detailsIndex + 1, details: data[detailsIndex + 1] });
  }

  render() {
    const { type, data, details, detailsIndex, page, pageCount, cover } = this.state;

    return (
      <section className="Q-media">
        <div style={{ background: `url("${cover}") center/cover`, width: '100%', height: '100%' }}>
          <h1 className="Q-G-page-starting">{Translations.texts.media}</h1>
        </div>
        <div className="Q-content">
          <div className="Q-filter">
            <h3 className={type === typeEnum.Photo ? 'Q-chosen' : ''} onClick={() => this.changeType(typeEnum.Photo)}>{Translations.texts.photoarchive}</h3>
            <h3 className={type === typeEnum.Video ? 'Q-chosen' : ''} onClick={() => this.changeType(typeEnum.Video)}>{Translations.texts.videoarchive}</h3>
          </div>
          <div className="Q-content">
            {data.map((item, index) => <div
              key={item.id}
              style={{ background: `url("${item.image}") center/cover` }}
            >
              <h4 onClick={() => this.setState({ details: item, detailsIndex: index })}>{item.title}</h4>
              {item.youtube_video_id && <img src={PlayIcon} alt="play" />}
            </div>)}
          </div>
        </div>
        {details && <Modal onClose={() => this.setState({ details: null, detailsIndex: null })}>
            {details.youtube_video_id ? <iframe
              src={`https://www.youtube.com/embed/${details.youtube_video_id}`}
              title="media_video"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              className="Q-G-media-details-modal Q-media-details"
              allowFullScreen
            /> : <div
              className="Q-G-media-details-modal Q-media-details"
              style={{ background: `url("${details.image}") center/contain no-repeat` }}
            />}
            <div className="Q-media-prev-next">
              {data[detailsIndex - 1] && <button onClick={this.prev} className="Q-prev"><i className="icon-ic_back" /> <span>{Translations.texts.prev}</span></button>}
              {(data[detailsIndex + 1] || page < pageCount) && <button onClick={this.next} className="Q-next"><span>{Translations.texts.next}</span> <i className="icon-ic_next" /></button>}
            </div> 
        </Modal>}
      </section>
    );
  }
}

export default Media;