import React from 'react';
import Structure_img from '../images/structure_en_big.png';


export default {
    home: "Home",
    media: "Media",
    library: "Library",
    search: "Search...",
    about_us: "About us",
    activity: "Activity",
    contact_us: "Contact us",
    other: "Other",
    navigate_to_news: "Navigate to News",
    navigate_to_events: "Navigate to Events",
    navigate_to_reports: "Navigate to Reports",
    office_address: "0070, Yerevan, Yervand Kochar 20",
    home_media_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit amet lacus et felis pulvinar sodales id a massa. Proin vulputate rutrum turpis, in molestie odio. Cras vel ex sit amet justo hendrerit imperdiet posuere ac orci. Mauris sollicitudin scelerisque risus laoreet dictum. Etiam sodales justo ut lacinia commodo. Integer.",
    home_about_us_description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sit amet lacus et felis pulvinar sodales id a massa. Proin vulputate rutrum turpis, in molestie odio. Cras vel ex sit amet justo hendrerit imperdiet posuere ac orci. Mauris sollicitudin scelerisque risus laoreet dictum. Etiam sodales justo ut lacinia commodo. Integer.",
    slider_title: <>&laquo;Քաղաքացիների ձայնն ու գործողությունները Հայաստանի խոշորացրած Համայնքներում&raquo;</>,
    slider_subtitle: <>Հունվարի 25-ին &laquo;Գյումրի Տեխնոլոգիական կենտրոնում&raquo; տեղի ունեցավ &laquo;Քաղաքացիների
        ձայնն ու գործողությունները Հայաստանի խոշորացված Համայնքներում&raquo; ծրագրի պաշտոնական մեկնարկի միջոցառումը</>,
    newspaper: "Newspaper",
    news_and_posts: "News, Posts",
    contact_form_text: "We will be happy to answer any questions!",
    name: "Name",
    email: "E-mail",
    message: "Message",
    send: "Send",
    read: "Read",
    association: "Association",
    establishment: "Establishment",
    mission: "Mission",
    vision: "Vision",
    structure: "Structure",
    structure_img_big: Structure_img,
    president: "President",
    administration: "Administration",
    members: "Members",
    committees: "Committees",
    address: "Address",
    addressValue: "Yerevan, Yervand Qochar's 20",
    phone: "Phone",
    state: "State",
    community: "Community",
    regulations: "Regulations",
    name_surname: "Name, Surname",
    surname: "Surname",
    establishment_title: "Establishment",
    establishment_content: "In late 1996 the process of formation of the local self-government system in the Republic of Armenia began. In 1997-1999, in order to assist the process, the International City Management Association (ICMA) in co-operation with the School of Public Administration of Armenia started to implement the Project on Modernization of Local Finance Management. The Project participants were deputy heads of communities and heads of financial departments. The purpose of the Project was to train the participants in local budgeting, to transfer corresponding knowledge and to introduce international experience. The Project was accomplished with a great success, and the faculty and participants of the Project, assuming that foundation of NGO’s would have an important role in formation and development of local government system, convened a founding meeting and established the Community Finance Officers Association on September 24, 1998 . CFOA began its activity at the beginning of its foundation.",
    mission_title: "CFM Mission",
    mission_content: "To assist strengthening and development of local self-government system in Armenia by addressing existing issues and challenges and by participating, on one side,  in the development of respective policies, legal framework and methodological tools, complying them with modern methods and international criteria of community governance and, on the other side, tackling implementation of existing policies, legislative requirements  and conceptual approaches.",
    vision_title: "CFM Vision",
    vision_content: "Communities Finance Officers Association is the key pillar of the RA self-government system equipped with necessary resources to carry out activities, cutting-edge information and communication systems, skillful experts and professionals renowned  both in Armenian and abroad. The organization serves as a platform of comprehensive professional discussions and dialogues for state bodies, local authorities, civil society and private sector, who make and implement policies in the field of local governance to support straightening and development of local self-government system.",
    photoarchive: "Photoarchive",
    videoarchive: "Videoarchive",
    education: "Education",
    working_experiance: "Working experiance",
    president_name: "Vahan Movsisyan",
    president_education: [{
        date: '1974-1979',
        about: 'Yerevan Polytechnic Institute, Yerevan (Armenia)',
    },
        {
            date: '1981-1985',
            about: 'Moscow Institute of Economics',
        },
        {
            date: '1997-1998',
            about: '“Modernization of Financial Management”, course of ICMA, USAID',
        }],
    president_working_experiance: [{
        date: '1998-Present',
        about: '“Communities Finance Officers Association” NGO, Chairman',
    },
        {
            date: '2018-Present',
            about: '"Citizens voice and actions in consolidated communities" (EU), Chief of Party',
        },
        {
            date: '2014-Present',
            about: '“Civic Engagement in Local Governance” (USAID),Chief of Party',
        },
        {
            date: '2014-2019',
            about: '“Support to elaboration of 2018 program budgets in the 18 established cluster municipalities of Armenia” (GIZ-Armenia), Project Director',
        },
        {
            date: '2017-2018',
            about: '“Development of municipal Annual Work Plans (AWP) for 12 enlarged municipalities” (GIZ-Armenia), Project Director',
        },
        {
            date: '2016-2017',
            about: '“Local Finance Benchmarking toolkit and pilot in Armenia”, (Council of Europe), Local Expert',
        },
        {
            date: '2015-2016',
            about: '“Elaboration of Development of Regional Programs in Tajikistan”-(UNDP Tajikistan), International Consultant',
        },
        {
            date: '2013-2014',
            about: '“Enhancing Budget Transparency and Financial Accountability in General Education System in Yerevan” (OSF-Armenia), Project Director',
        },
        {
            date: '2008-2014',
            about: '“Support to the Development of the Local-Government System of Tajikistan”, (UNDP Tajikistan), International Expert',
        },
        {
            date: '2012-2013',
            about: '“Support to the development of Local Social Plans (LSP) in Lori and Tavush marzes and institutionalization of the LSP framework in Armenia” (UNICEF), Expert',
        },
        {
            date: '2011',
            about: '“Capacity Assessment of Local Governance on participatory policy and planning” (UNDP Armenia), Expert',
        },
        {
            date: '2010-2011',
            about: '“Yerevan Budget Monitoring” (OSI-Armenia), Project Director',
        },
        {
            date: '2006-2008',
            about: '“Monitoring of Road Constructions within the Frameworks of MCA-Armenia Project” (OSI/MCA-Armenia), Project Director,',
        },
        {
            date: '2005-2006',
            about: '“The Development of Financial Equalization Mechanism in Armenia” (OSI/FDI), Project Director',
        },
        {
            date: '2004-2005',
            about: '“Study on Infrastructure Development Project in the Rural Area in Armenia” (World Bank), Program Director',
        },
        {
            date: '2000-2001',
            about: '“Assistance to Creation Information-Analytical Centers in Communities of RA” (Eurasia Foundation), Project Director',
        },
        {
            date: '1997-1999',
            about: 'Adviser of the Mayor of city Ashtarak',
        },
        {
            date: '1994-1996',
            about: 'Deputy Mayor of city Ashtarak',
        },
        {
            date: '1991-1996',
            about: 'Chairman of Budget Commission of city Ashtarak',
        }],
    president_publications: [
        {name: 'Local Governance Annual Index (LGAI) methodology, co-author, 2017'},
        {name: `Guidebook for organization and implementation of the initiative on including question to municipality council's session agenda, co-author, 2017`},
        {name: 'Methodology of program budgeting in RA Local Self-Government System, co-author, 2015'},
        {name: '"The Development of Financial Equalization Mechanism in Armenia" (OSI/FDI), 2007, author'},
        {name: '"Performance Budgeting in Local Self-Government System" (UNDP), 2006, author'},
        {name: 'Principals of Local Self-government, 1999, author'},
        {name: 'The methodical instruction "Conducting the Municipal Budgets" ICMA (USAID), 1998, author'},
    ],
    president_languages: [
        {name: 'Armenian'},
        {name: 'Russian'},
        {name: 'English'},
    ],
    department_members: "Board members",
    department_members_list: ['Vahram Shahbazyan', 'Nora Ghukasyan', 'Abraham Artashesyan', 'Seyran Markosyan', 'Tigran Brsikyan', 'Gohar Davtyan', 'Karen Hovhannesyan', 'Levon Tumanyan', 'Ruben Hayrapetyan', 'Davit Margaryan', 'Qnarik Ayvazova', 'Artsruni Igityan', 'Anna Chobanyan', 'Alisa Mirumyan'],
    honorable_members: "Honorable members",
    members_content: <>
        <h4 className="Q-members-title">9th GENERAL ASSEMBLY OF COMMUNITIES<br/>
            FINANCE OFFICERS ASSOCIATION<br/>
            DECREE<br/>
            ON SETTING THE AMOUNTS OF ENTRANCE FEE AND MEMBERSHIP FEE
        </h4>
        <p>
            July 11, 2017<br/><br/>
            Considering the Articles 6.4 and 6.7 of the Charter of the Community Finance Officers
            Association NGO, the 9th General Assembly of the CFOA decides to set the amounts of entrance fee
            and membership fee.
        </p>
        <h4 className="Q-members-title">1. Entrance fee is set with the following amounts:</h4>
        <p>
            a) for individuals – 1 000 (one thousand) AMD,<br/>
            b) for municipalities – 10 000 (ten thousand) AMD.
        </p>
        <h4 className="Q-members-title">2. Annual membership fee is set with the following amounts:</h4>
        <p>
            a) for individuals – 3 000 (three thousand) AMD,<br/>
            b) for municipalities:<br/><br/>
            &ensp;- with population less than 10 thousand – 20 000 (twenty thousand) AMD<br/>
            &ensp;- with population 10 to 20 thousand – 40 000 (forty thousand) AMD<br/>
            &ensp;- with population 20 to 30 thousand – 60 000 (sixty thousand) AMD<br/>
            &ensp;- with population 30 to 40 thousand – 80 000 (eighty thousand) AMD<br/>
            &ensp;- with population 40 to 50 thousand – 100 000 (one hundred thousand) AMD<br/>
            &ensp;- with population 50 to 200 thousand – 150 000 (one hundred and fifty thousand) AMD<br/>
            &ensp;- with population more than 200 thousand – 250 000 (two hundred and fifty thousand) AMD
        </p>
        <h4 className="Q-members-title">If you want to become a member of CFOA</h4>
    </>,
    apply: "Apply",
    members_list: "List of members of CFOA",
    artyusha: <>
        <h4 className="Q-title">Artyusha Muradyan</h4>
        <h5>1949-2006 (posthumous)</h5>
        <p>
            (CFOA Board Decree on 13 May <span>2008</span>)<br/>
            Founding member of CFOA since <span>1998</span>,<br/>
            <span>1998–2006</span> CFOA Board member
        </p>
    </>,
    rudik: <>
        <h4 className="Q-title">Rudik Haroyan</h4>
        <h5>1948-2013</h5>
        <p>
            (CFOA Board Decree on 27 April <span>2010</span>)<br/>
            Founding member of CFOA since <span>1998</span>,<br/>
            <span>1998–2006</span> CFOA Board member
        </p>
    </>,
    david: <>
        <h4 className="Q-title">Davit Tumanyan</h4>
        <h5>1963–2016 (posthumous)</h5>
        <p>
            (CFOA Board Decree on 23 December <span>2016</span>)<br/>
            Founding member of CFOA since <span>1998</span>,<br/>
            <span>2005–2016</span> CFOA Board member<br/>
            <span>2005–2016</span> CFOA Vice-President <br/>
        </p>
    </>,
    volodya: <><h4 className="Q-title">Volodya Vasilyan</h4>
        <h5>Born 1946</h5>
        <p>
            (CFOA Board Decree on 27 April <span>2010</span>)<br/>
            Founding member of CFOA since <span>1998</span>,<br/>
            <span>1998–2006</span> CFOA Board member
        </p>
    </>,
    our_team_hasmik: <>
        <h4 className="Q-title">Hasmik Manasyan (Grigoryan)</h4>
        <h5>Local governance and financial management expert</h5>
        <p>
            Hasmik Manasyan joined CFOA as a volunteer in March 2015. From 2015 to 2016 she worked in GIZ as an expert
            on project budgeting. In 2017 she was working on various projects implemented by CFOA as a project
            coordinator. Starting from 2018 she is a local governance and financial management expert in CFOA. Hasmik
            has participated in the development of program budgets and the annual work plan methodology. Since 2018 she
            is co-author of the "Local Self-Government in Armenia" and "Annual Local Government Index" books developed
            by CFOA expert team.<br/> <br/>
            Hasmik holds a master’s degree in Public Finance Management Department from Public Administration Academy.
            Among her interests are ecotourism and development of agrotourism in Armenia.
        </p>
    </>,

    our_team_hasmik_short: <>
        <h4 className="Q-title">Hasmik Manasyan (Grigoryan)</h4>
        <h5>Local governance and financial management expert</h5>
        <p>
            Hasmik Manasyan joined CFOA as a volunteer in March 2015. From 2015 to 2016 she worked in GIZ as an expert
            on project budgeting. In 2017 she was working on various projects implemented by CFOA as a project
            coordinator. Starting from 2018 she is a local governance and financial management expert in CFOA. Hasmik
            has participated in the development of program budgets and the annual work plan methodology. <br/>
        </p>
    </>,
    our_team_karen: <>
        <h4 className="Q-title">Karen Hovhannesian</h4>
        <h5>Expert</h5>
        <p>
            Karen Hovhannesian joined CFOA team in 2016 and has about 20 years of work experience in local
            self-government sphere. Karen started his experience since 1999 in Sisian municipality, where he has managed
            different community development projects. Since 2012 as a deputy mayor he has coordinated collaboration and
            cooperation with international organizations and Sisian sister cities.<br/> <br/>
            Karen graduated from Yerevan State University's Faculty of Philosophy, Sociology and Psychology, and
            participated in various professional training programs.<br/> <br/>
            In 2019 Karen Hovhannesian was elected as Chairman of the governing board of Goris State University.
        </p>
    </>,

    our_team_karen_short: <>
        <h4 className="Q-title">Karen Hovhannesian</h4>
        <h5>Expert</h5>
        <p>
            Karen Hovhannesian joined CFOA team in 2016 and has about 20 years of work experience in local
            self-government sphere. Karen started his experience since 1999 in Sisian municipality, where he has managed
            different community development projects. Since 2012 as a deputy mayor he has coordinated collaboration and
            cooperation with international organizations and Sisian sister cities.<br/>
        </p>
    </>,

    our_team_anna: <>
        <h4 className="Q-title">Anna Ghorukhchyan</h4>
        <h5>Local governance expert</h5>
        <p>
            Anna Ghorukhchyan has joined the CFOA team since 2016 as an intern. She has been working as a local
            governance and financial management expert at “CELoG” program (implemented by USAID) since 2018. Besides,
            she has taken part in the development process of program budgets and annual work plans for multi-settlement
            municipalities implemented by GIZ.<br/> <br/>
            Anna holds Bachelor’s and Master’s degrees in State and Municipal Management from the Armenian State
            University of Economics (department of Management). Within the framework of “Erasmus+” program she has
            studied at the faculty of Economics at the university of Miskolc, Hungary.<br/> <br/>
            She has been involved in various trainings, both national and international, such as “Young Local Leaders:
            The Future for Local Democracy” (Council of Europe), “Teaching Tolerance Through English” summer camp (US
            Embassy Turkey), “The EU and the Caucasus in 2030 - Envisioning the Future” workshop (University of
            Tubingen).<br/> <br/>
            Along with local governance Anna is interested in governance issues of higher education. 
        </p>
    </>,
    our_team_anna_short: <>
        <h4 className="Q-title">Anna Ghorukhchyan</h4>
        <h5>Local governance expert</h5>
        <p>
            Anna Ghorukhchyan has joined the CFOA team since 2016 as an intern. She has been working as a local
            governance and financial management expert at “CELoG” program (implemented by USAID) since 2018. Besides,
            she has taken part in the development process of program budgets and annual work plans for multi-settlement
            municipalities implemented by GIZ.<br/>
        </p>
    </>,
    our_team_kristine: <>
        <h4 className="Q-title">Kristine Atomyan</h4>
        <h5>Office manager</h5>
        <p>
        Kristine joined CFOA in 2001. Being a cashier-referent, she ensures daily administrative and organizational work of the office.
        She is also responsible for assistance in various project. Starting from 2014, Kristina is the office manager at CFOA.<br/> <br/>
        Kristine studied at Ashtarak Russian School, and graduated from Faculty of Informatics and Applied Mathematics at Yerevan State University.
        </p>
    </>,

    our_team_kristine_short: <>
        <h4 className="Q-title">Kristine Atomyan</h4>
        <h5>Office manager</h5>
        <p>
        Kristine joined CFOA in 2001. Being a cashier-referent, she ensures daily administrative and organizational work of the office.
        She is also responsible for assistance in various project. Starting from 2014, Kristina is the office manager at CFOA.<br/>
        </p>
    </>,
    download: "Download",
    programs: 'Programs',
    all: 'All',
    active: 'Active',
    publications: 'Publications',
    languages: 'Languages',
    finished: 'Finished',
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    months_long: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    foundation: "Foundation",
    download_file: "Download file",
    objectives: "Objectives",
    committees_subtitle: "CFOA Board includes 3 committees",
    committees_content: [{
        title: 'Organizational, Information and Public Relations Committee',
        president: "Seyran Markosyan",
        members: ["Nora Ghukasyan", "Alisa Mirumyan", "Levon Tumanyan"],
        objectives: [
            "Submit proposals for membership to the Association or suspension of it to the Board ",
            "Ensure timely payment of membership fees",
            "Prepare proposals on revenue and expenditure directions of the Association’s own (non-grant) resources and supervise their implementation ",
            "Organize preparatory activities for the convention of the Association",
            "Liaise with economic companies, branches and their representatives",
            "Draft decisions relating to the field and submit them to the Board ",
            "Coordinate relations with other CSOs or other civil society representatives ",
            "Coordinate media relations with the Association \n" +
            "Invite media representatives to various events \n" +
            "Provide information to media representatives regarding the activities and projects implemented by the Association ",
            "Disseminate official opinion of the Association via media through statements, interviews, analytical or other articles, etc. ",
            "Clarify any distorted information regarding the Association or its activities through media ",
            "Circulate information covering the activities of the Association among its members and others interested individuals   ",
        ],
    },
        {
            title: 'Committee on Legislation, Legal Issues, and Methodological and Advisory Support to Communities',
            president: "Vahram Shahbazyan",
            members: ["Qnarik Ayvazova", "Artsruni Igityan", "Gohar Davtyan", "Anna Chobanyan", "Tigran Briskyan", "Karen Hovhannesyan "],
            objectives: [
                "Draft laws and regulations in the field of local self-government and organize discussions with local self-governance bodies (LSGs) and public at large.",
                "Draft legislative amendments and initiate discussions with the Standing Committees of the National Assembly of the Republic of Armenia, as well as competent authorities.",
                "Develop, publish and disseminate interpretation, explanatory and methodological instructions in regard to adopted legislative acts.",
                "Organize conferences and seminars on local self-government issues",
                "Protect interests of communities and supervise implementation of laws by the state authorities.",
                "Organize discussions on initiatives in Armenian communities. These are aimed to improve the legislative framework of local self-government.",
                "Share information   on local self-government with community staff members dealing with socio- economic and financial management issues, improve their skills and competences.",
                "Provide advisory and technical support to community staff in drafting the five-year community development plans, annual work plans, budgets, as well as assist in monitoring their implementation, reporting and other issues.",
                "Organize trainings, seminars for financial management professionals, draft and provide training and methodological materials, in collaboration with international and local organizations.",
                "Test and apply modern methods and mechanisms of financial management (Western, Eastern European, CIS countries) in Armenian communities, assess their effectiveness .",
                "Ensure exchange of international experience among communities of the Republic of Armenia, introduce advanced experience, boost cooperation with rural communities.",
                "Protect interests of community finance officers and other employees, increase their role.",
                "Gradually increase spectrum of services provided to the communities by CFOA."
            ],
        }, {
            title: 'International Relations Committee',
            president: "Abraham Artashesyan ",
            members: ["Davit Margaryan ", "Ruben Hayrapetyan "],
            objectives: [
                "Establish cooperation and partnership with NGOs in the sphere of local government ",
                "Establish links with international organizations in the sphere of local government ",
                "Take part in public administration reforms as well as international forums, conferences and seminars dedicated to decentralization and local self-government issues ",
                "Draft proposals to submit to donor organizations, implement various projects ",
                "Study international experience in the field of local government, share the acquired information with communities as well as look for ways to apply it in Armenia  ",
                "Organize regional and international conferences and seminars ",
                "Prepare articles and researches and organize their publication in national and foreign media "
            ],
        },
    ],
    events: 'Events',
    eventgo: 'I will participate',
    news: 'News',
    prev: "Prev",
    next: "Next",
    recomended: "Recomended",
    from: "From",
    our_team: "Our team",
    photos: "Photos",
    files: "Files",
    home_library_short_description: 'Library section includes publications carried out by CFOA team as well as other articles and books on local governance.',
    home_media_short_description: 'Media section includes photos and videos reflecting all significant moments of our events.',
    home_newspaper_short_description: 'Newspaper section includes information on organized and upcoming events by CFOA as well as main developments of local governance system.',
    calculator: 'Calculator',
    choose: 'Choose',
    generate: 'Generate',
    value: 'Value',
    back: 'Back',
    eventend: 'Ended',
    page: 'page',
    of: 'of',
    seemore: "See more",
    close_text: "Close",
    read_ptogramm : "See program",
    armenian: "Armenian",
    english: "English",
    cfoa_developed: "Developed by CFOA",
    start_date: "Start date",
    end_date: "End date",
    lang: "en"
};