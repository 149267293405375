import Settings from './settings';
import { LanguageEnum } from '../constants/enums';

import ARMENIAN from '../../assets/translations/am';
import ENGLISH from '../../assets/translations/en';

class Translations {

    static get texts() {
        switch (Settings.Language) {
            case LanguageEnum.Armenian: return ARMENIAN;
            default: return ENGLISH;
        }
    }
};

export default Translations;