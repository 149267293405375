import React, { Component } from 'react';

import Carousel from './components/carousel';
import Posts from './components/posts';
import AboutUs from './components/about-us';
import PagesShortDetails from './components/pages-short-details';
import News from './components/news';

import homeApis from '../../platform/api/home';

import './style.scss';

class Home extends Component {

  state = {
    firstSection: null,
    secondSection: null
  }

  componentDidMount = async() => {
    const res = await homeApis.getSections();
    if (res && res.success) {
      this.setState({
        firstSection: res.data.find(item => item.position === 1),
        secondSection: res.data.find(item => item.position === 2)
      })
    }
  }

  render () {

    const { firstSection, secondSection } = this.state;

    return (
      <div className="Q-home">
        <Carousel />
        <Posts />
        {!!firstSection && <AboutUs item={firstSection} />}
        <PagesShortDetails />
        {!!secondSection && <AboutUs item={secondSection} />}
        <News />
      </div>
    )
  }

}

export default Home;