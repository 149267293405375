import React, { Component } from 'react';
import { evaluate } from 'mathjs';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { Line, Bar } from 'react-chartjs-2';
import Slider from 'rc-slider';
import arrayMove from 'array-move';

import Translations from '../../platform/services/translations';
import FileController from '../../platform/api/file';
import PageLoader from '../../components/page-loader';

import './style.scss';

const Range = Slider.createSliderWithTooltip(Slider.Range);

const SortableItem = sortableElement(({ onClick, value }) => <div className="Q-calculator-drag-item" onClick={onClick}><DragHandle /> {value}</div>);
const SortableContainer = sortableContainer(({ children }) => <div className="Q-list">{children}</div>);
const DragHandle = sortableHandle(() => <span>:::</span>);

class Calculator extends Component {

  state = {
    data: null,
    minValue: null,
    maxValue: null,
    expression: '',
    generatedData: null,
    barView: false,
    openedCount: 0,
    dragIndex: null,
    mouseInArea: false

  }

  async componentDidMount() {
    const result = await FileController.calcUser();

    this.setState({
      data: result.data,
      minValue: Math.min(...result.data.rows.map(item => item.value)),
      maxValue: Math.max(...result.data.rows.map(item => item.value)),
    });
  }

  get valid() {
    try {
      const { data } = this.state;
      const testData = {};
      data.rows[0].valueArr.forEach(item => testData[item.key] = item.value);

      this.runExpression(testData);

      return true;
    } catch (e) { return false; }
  }

  runExpression = data => {
    const { expression } = this.state;
    const replacedExpression = expression
      .replace(new RegExp('×', 'g'), '*')
      .replace(new RegExp('÷', 'g'), '/')
      .replace(new RegExp('sqr', 'g'), 'square')
      .replace(new RegExp('√', 'g'), 'sqrt');

    return evaluate(replacedExpression, data);
  }

  changeExpression = value => this.setState({ expression: value });

  isAction = letter => {
    return (
      letter === '-' ||
      letter === '+' ||
      letter === '÷' ||
      letter === '×' ||
      letter === '√' ||
      letter === '(' ||
      letter === 'sqrt'
    );
  }

  addAction = value => {
    const { expression, openedCount } = this.state;
    const lastLetter = expression[expression.length - 1];
    const radicOrSqrValid = (this.isAction(lastLetter) || !expression.length || lastLetter === '(') && lastLetter !== ')'; 
    if (value === '√(' || value === 'sqr(' ? radicOrSqrValid : !this.isAction(lastLetter) && expression.length) {
      this.setState({ expression: expression + value, openedCount: value === '√(' || value === 'sqr(' ? openedCount + 1 : openedCount });
    }
  }

  addSymbol = value => {
    const { expression, openedCount } = this.state;
    const lastLetter = expression[expression.length - 1];
    const leftSymbol = this.isAction(lastLetter) || lastLetter === '(' || !expression.length;
    const rightSymbol = !this.isAction(lastLetter) && expression.length;
    if (value === '(' && lastLetter !== ')' && leftSymbol) {
      this.setState({ expression: expression + value, openedCount: openedCount + 1 });
    } else if (value === ')' && lastLetter !== '(' && rightSymbol && openedCount > 0) {
      this.setState({ expression: expression + value, openedCount: openedCount - 1 });
    }
  }

  addItem = value => {
    const { expression, openedCount } = this.state;
    const lastLetter = expression[expression.length - 1];
    if (!expression.length || this.isAction(lastLetter) || lastLetter === '(') {
      this.setState({ expression: expression + value, openedCount: value === '(' ? openedCount + 1 : openedCount });
    }
  }

  onSortStart = (data) => {
    this.setState({ dragIndex: data.index });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { data } = this.state;
    data.cols = arrayMove(data.cols, oldIndex, newIndex);
    this.setState(({ data, dragIndex: null }));
  };

  generate = (min, max) => {    
    try {
      const { data } = this.state;
      const rows = min && max ? data.rows.filter(item => item.value >= min && item.value <= max) : data.rows;
      const generatedData = rows.map(item => {
        const keysData = {};
        item.valueArr.forEach(item => keysData[item.key] = item.value);
  
        return {
          x: item.value,
          y: this.runExpression(keysData),
        };
      });
  
      this.setState({ generatedData });
    } catch (e) { return false; }
  }

  chartData = () => {
    const { barView, generatedData } = this.state;

    return {
      labels: generatedData.map(item => item.x),
      datasets: [{
        label: "",
        data: generatedData.map(item => item.y),
        borderColor: 'rgba(255, 165, 0, .5)',
        pointBackgroundColor: 'orange',
        pointBorderWidth: 0,
        backgroundColor: barView ? 'rgba(255, 165, 0, .5)' : 'transparent',
        borderWidth: 5,
        borderCapStyle: 'round',
      }],
    };
  }

  rangeChange = ([min, max]) => this.generate(min, max);

  onMouseOver = () => {
    this.setState({ mouseInArea: true });
  }

  onMouseUp = () => {
    const { mouseInArea, dragIndex, data } = this.state;
    if (mouseInArea && dragIndex >= 0) {
      const item = data.cols[dragIndex];
      if (item) this.addItem(item.key);
      this.setState({ mouseInArea: false, dragIndex: null });
    }
  }

  download = () => {
    const canvas = document.getElementsByClassName('chartjs-render-monitor')[0];
    const a = document.createElement('a');
    a.setAttribute('download', 'result.bmp');
    a.href = canvas.toDataURL('image/png').replace("image/png", "image/x-ms-bmp");
    a.click();
  }

  render() {
    const { data, barView, minValue, maxValue, expression, generatedData } = this.state;

    return data ? (
      <section className="Q-calculator">
        {!generatedData ? <div className="Q-content">
          <div className="Q-calculator-block">
            <h3><span className="Q-G-title-tringle" /> {Translations.texts.calculator}</h3>
            <textarea
              onMouseOver={this.onMouseOver}
              onDragEnd={this.onDragEnd}
              onMouseUp={this.onMouseUp}
              // className={!this.valid ? 'Q-G-invalid-field' : ''}
              value={expression}
              onChange={e => this.changeExpression(e.target.value)}
            />
            <div className="Q-actions">
              <button onClick={() => this.addAction('+')}>+</button>
              <button onClick={() => this.addAction('-')}>-</button>
              <button onClick={() => this.addAction('÷')}>&divide;</button>
              <button onClick={() => this.addAction('×')}>&times;</button>
              <button onClick={() => this.addAction('√(')}>&radic;</button>
              <button onClick={() => this.addAction('sqr(')}>x<sup>2</sup></button>
              <button onClick={() => this.addSymbol('(')}>(</button>
              <button onClick={() => this.addSymbol(')')}>)</button>
            </div>
            <button
              disabled={!this.valid || !expression.length}
              style={(!this.valid || !expression.length) ? { cursor: 'default' } : {}}
              onClick={this.generate}
              className="Q-G-radius-button"
            >{Translations.texts.generate}</button>
          </div>
          <div className="Q-choose-block">
            <h3>{Translations.texts.choose}</h3>
            <SortableContainer onSortStart={this.onSortStart} onSortEnd={this.onSortEnd} useDragHandle>
              {data.cols.map((item, index) => <SortableItem
                index={index}
                key={item.key}
                onClick={() => this.addItem(item.key)}
                value={`${item.title} (${item.key})`}
              />)}
            </SortableContainer>
          </div>
        </div> : <div className="Q-content">
          <div className="Q-chart">
            <h3 onClick={() => this.setState({ generatedData: null })}><span>&larr;</span>  {Translations.texts.back}</h3>
            <h5 className={`Q-G-simple-switcher ${barView ? 'Q-selected' : ''}`} onClick={() => this.setState({ barView: true })}>Bar</h5>
            <h5 className={`Q-G-simple-switcher ${!barView ? 'Q-selected' : ''}`} onClick={() => this.setState({ barView: false })}>Line</h5>


            <button onClick={this.download} className="Q-G-radius-button">Download as BMP</button>


            <Range min={minValue} max={maxValue} step={1} defaultValue={[minValue, maxValue]} onAfterChange={this.rangeChange} />
            {barView ? <Bar
              data={this.chartData}
              options={{
                elements: { point: { radius: 6 } },
                scales: { xAxes: [{
                  gridLines: { display: false }   
                }] }
              }}
            /> : <Line
              data={this.chartData}
              options={{
                elements: { point: { radius: 6 } },
                scales: { xAxes: [{
                  gridLines: { display: false }   
                }] }
              }}
            />}
          </div>
        </div>}
      </section>
    ) : <PageLoader />;
  }
}

export default Calculator;