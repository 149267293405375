import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Slider from 'react-slick';

import HomeController from '../../../../platform/api/home';
import ROUTES from '../../../../platform/constants/routes';
import { detailsTypeEnum } from '../../../../platform/constants/enums';
import Translations from '../../../../platform/services/translations';


import NewsShape from '../../../../assets/images/circular.svg';
import EventShape from '../../../../assets/images/cilurarEvent.svg';

import './style.scss';

class Carousel extends Component {

	state = {
		data: []
	}

	componentDidMount() { this.getSlideShow(); }

	getSlideShow = async () => {
		const res = await HomeController.getSlide();
		if (res && res.success) {
			this.setState({ data: res.data });
		}
	}

	render () {
		const { data } = this.state;

		return data.length ? ( 
			<Slider infinite={true} autoplay={true} autoplaySpeed={5000} arrows={false} dots className="Q-home-carousel">
				{data.map(item => (
					<div key={item.id}>
						<div style={{ background: `url(${item.cover}) center/cover` }} className="Q-home-carousel-item">
							<div className="Q-main-category-slider">
								<h2><Link to={item.type === detailsTypeEnum.news ? ROUTES.NEWS_DETAILS.replace(':id', item.id) : ROUTES.EVENTS_DETAILS.replace(':id', item.id)}>{item.title}</Link></h2>
							<Link to={item.type === 1 ? '/news' : '/events'}>
								<div className="Q-category-main">
									<div className="Q-category-slider"><img src={item.type === 1 ? NewsShape : EventShape} alt="Category" /></div>
									<h5 className="Q-text-category">{item.type === 1 ? Translations.texts.news : Translations.texts.events}</h5>
								</div>
							</Link>
								<p>{item.description ? getFormatted(item.description) : ''}</p>
							</div>
						</div>
					</div>
				))}
			</Slider>
		) : null;
	}

}

function getFormatted (description) {
	let formatted = description.split(' ').slice(0, 60).join(' ');
	if ([':', '...'].includes(formatted[formatted.length - 1])) {
		return formatted;
	} else if ([',', '`', '.'].includes(formatted[formatted.length - 1])) {
		return formatted.substr(0, formatted.length - 1) + '...'
	} else {
		return formatted + '...'
	}
}


export default withRouter(Carousel);