import React, { Component, createRef } from 'react';

import Translations from '../../../../platform/services/translations';
import MainController from '../../../../platform/api';
import Connection from '../../../../platform/services/connection';
import { scrolledToBottom } from '../../../../platform/services/helper';
import { searchTypeEnum } from '../../../../platform/constants/enums';
import NewsItem from '../../../../modules/news/components/Manage';
import BooksItem from '../../../../modules/library/components/book';
import EventsItem from '../../../../modules/events/components/Manage';
import ProgramsItem from '../../../../modules/programs/components/Manage';

import './style.scss';

class Search extends Component {

  state = {
    search: '',
    page: 1,
    data: [],
    perPage: 10,
  }

  findedBox = createRef();

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen) setTimeout(() => {
      this.findedBox.current.addEventListener('scroll', this.onScroll);
      document.body.style.overflow = 'hidden';
    }, 0); else if (this.findedBox.current && document.body.style.overflow !== 'initial'){
      this.findedBox.current.removeEventListener('scroll', this.onScroll);
      document.body.style.overflow = 'initial';
    };
  }

  getList = async overwrite => {
    if (overwrite) Connection.AbortAll();
    const { data, page, perPage, search } = this.state;
    this.setState({ loading: true }, async () => {
      const result = await MainController.Search({ perPage, page, q: search });
      if (result && result.success) {
        this.setState({
          data: overwrite ? result.data.itemList : [...data, ...result.data.itemList],
          pageCount: result.data.pageCount,
          loading: false
        });
      }
    });
  }

  Item = ({ item }) => {
    switch (item.type) {
      case searchTypeEnum.news: return <NewsItem item={item} />;
      case searchTypeEnum.books: return <BooksItem details={item} />;
      case searchTypeEnum.events: return <EventsItem item={item} />;
      default: return <ProgramsItem item={item} />;
    }
  }
  
  componentWillUnmount () {
    window.removeEventListener('scroll', this.onScroll);
  }

  onSearchChange = e => this.setState({ search: e.target.value }, async () => {
    const { search } = this.state;
    if (search.length > 2) {
      Connection.AbortAll();
      this.getList(true);
    } else this.setState({ page: 1, data: [] });
  });

  onScroll = () => {
    if (scrolledToBottom(this.findedBox.current) && this.state.page !== this.state.pageCount && !this.state.loading) {
      this.setState({ page: this.state.page + 1 }, async () => {
        this.getList();
      })
    }
  }

  render() {
    const { data } = this.state;
    const { isOpen } = this.props;

    return <>
      <div className={`Q-search-content ${isOpen ? 'Q-open' : ''}`}>
        <input
          type="text"
          onChange={this.onSearchChange}
          className="Q-G-bordered-input"
          placeholder={Translations.texts.search}
        />
      </div>
      {isOpen && <div className="Q-search-finded" ref={this.findedBox}>
        {!!data && !!data.length && <>
          {data.map(item => <this.Item key={item.id} item={item} />)}
        </>}
      </div>}
    </>;
  }
}

export default Search;