import React, {Component, Fragment} from 'react';

import Modal from '../../../../components/modal';
import Translations from '../../../../platform/services/translations';
import BooksController from '../../../../platform/api/books';

import ArmenianFlag from '../../../../assets/images/armenia.svg';
import UKFlag from '../../../../assets/images/uk.svg';
import Hashtag from '../../../../assets/images/hashtag.svg';
import CfoaDeveloped from '../../../../assets/images/shield.svg';
import DownloadIcon from '../../../../assets/images/download.png';
import EyeIcon from '../../../../assets/images/eye.png';

import './style.scss';
import {isChrome} from '../../../../platform/services/browser';

class Book extends Component {

    state = {
        isBookModalOpen: false,
        details: ''
    }

    componentDidMount() {
        if (this.props.details) this.setState({details: this.props.details});
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.details) {
            this.setState({details: nextProps.details});
        }
    }

    closeBookModal = () => this.setState({isBookModalOpen: false});

    download = (e) => {
        const {details} = this.state;
        e && e.stopPropagation();
        BooksController.addDownload(details.id);
        const a = document.createElement('a');
        a.href = details.download_link;
        a.target = '_blank';
        a.download = true;
        details.download_count++;
        this.setState({details});
        document.body.appendChild(a);
        a.click();
    }

    openBook = (e) => {
        e && e.stopPropagation();
        const {details} = this.state;
        BooksController.addView(details.id);
        details.view_count++;
        if (isChrome()) {
            this.setState({isBookModalOpen: true, details});
        } else {
            const a = document.createElement('a');
            a.href = details.file_link;
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
        }
    }

    render() {
        const {details, isBookModalOpen} = this.state;
        return (
            <>
                {
                    details
                        ?
                        <Fragment>
                            <div onClick={this.openBook} className="Q-library-book">
                                <div className="Q-cover">
                                    <div style={{background: `url("${details.featured_image}") center/cover`}}/>
                                </div>
                                <div className="Q-details">
                                    <div className="Q-book-header">
                                        <h4>{details.title}</h4>
                                        <div className="Q-additional-info">
                                            <div className="Q-statistics-info Q-cfoa-developed Q-book-language"><img src={ (details.language === 1) ? ArmenianFlag : UKFlag} alt="language"/><p>{(details.language === 1) ? Translations.texts.armenian : Translations.texts.english}</p></div>
                                            <div style={{display: details.isCfoaDeveloped ? '' : 'none' }} className="Q-statistics-info Q-cfoa-developed"><img src={CfoaDeveloped} alt="By CFOA"/><p>{Translations.texts.cfoa_developed}</p></div>
                                            <div style={{display: details.tags.length ? '' : 'none' }} className="Q-statistics-info Q-cfoa-hashtag"><img src={Hashtag} alt="hashtag"/>{Object.keys(details.tags).map(item =>
                                                <h5 key={item} className="Q-hashtag">#{details.tags[item]}</h5>)}</div>
                                        </div>
                                    </div>
                                    {/*<div className="Q-hashtag-class">*/}
                                    {/*{Object.keys(details.tags).map(item => <h5 key={item}*/}
                                    {/*                                           className="Q-hashtag">#{details.tags[item]}</h5>)}*/}
                                    {/*</div>*/}
                                                                               <p>{details.description}</p>
                                    <button className="Q-G-radius-button"
                                            onClick={this.openBook}>{Translations.texts.read}</button>
                                    <button className="Q-download-button Q-library-download" download
                                            onClick={this.download}>{Translations.texts.download}</button>
                                    <h5 className="Q-statistics-info "><img src={EyeIcon} alt="eye"/> <span
                                        className="Q-statistics-values">{details.view_count}</span></h5>
                                    <h5 className="Q-statistics-info"><img src={DownloadIcon} alt="download"/> <span
                                        className="Q-statistics-values">{details.download_count}</span></h5>
                                </div>
                            </div>
                            {isBookModalOpen && <Modal onClose={this.closeBookModal}>
                                <embed src={details.file_link} type="application/pdf" className="Q-G-pdf-modal"/>
                            </Modal>}
                        </Fragment>
                        :
                        null
                }
            </>
        );
    }

}

export default Book;