export default {
  HOME: '/',
  ABOUT_US: {
    MAIN: '/about-us',
    ESTABLISHMENT: '/about-us/establishment',
    MISSION: '/about-us/mission',
    VISION: '/about-us/vision',
    STRUCTURE: '/about-us/structure',
    PRESIDENT: '/about-us/president',
    MEMBERS: '/about-us/members',
    DEPARTMENT_MEMBERS: '/about-us/department-members',
    HONORABLE_MEMBERS: '/about-us/honorable-members',
    COMMITTEES: '/about-us/committees',
    OUR_TEAM: "/about-us/our-team",
  },
  LIBRARY: '/library',
  MEDIA: '/media',
  CONTACT_US: '/contact-us',
  NEWSPAPER: '/newspaper',
  ACTIVITY: '/activity',
  NEWS: '/activity/news',
  NEWS_DETAILS: '/activity/news/:id',
  CALCULATOR: '/calculator',
  EVENTS: '/activity/events',
  EVENTS_DETAILS: '/activity/events/:id',
  PROGRAM_DETAILS: '/activity/program/:id'
};