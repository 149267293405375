import React, { PureComponent } from 'react';

import Filter from './components/filter';
import Book from './components/book';
import Translations from '../../platform/services/translations'
import BooksController from '../../platform/api/books';
import FileController from '../../platform/api/file';

import LibraryCover from '../../assets/images/library.jpg';

import Pagination from '../../components/pagination';

import './style.scss';
import { pageCoverTypeEnum } from '../../platform/constants/enums';

class Library extends PureComponent {

  state = {
    categories: null,
    filter    : {},
    page      : 1,
    perPage   : 10,
    pageCount : 1,
    data      : [],
    loading   : true,
    cover     : ''
  };

  componentDidMount = async() => {
    this.getList();
    this.getCover();
  }

  getList = async (scroll) => {
    const { page, perPage, filter } = this.state;
    this.setState({ loading: true }, async () => {
      const result = await BooksController.getList({ perPage, page, ...filter });
      if (result && result.success) {
        this.setState({
          data: result.data.itemList,
          pageCount: result.data.pageCount,
          loading: false
        }, () => {
          if (scroll) {
            window.scrollTo(0, 450);
          }
        });
      }
    });
  }
  
  getCover = async() => {
    const result = await FileController.getCover(pageCoverTypeEnum.library);
    if (result && result.success) this.setState({ cover: result.data });
    else this.setState({ cover: LibraryCover });
  }

  onFilterChange = filter => {
    if (!filter.key) delete filter.key;
    if (!filter.id) delete filter.id;
    this.setState({ filter, page: 1 }, () => this.getList());
  }

  getListByPageNo = (page) => {
    this.setState({ page: page }, () => {
      this.getList(true);
    });
  }

  render() {
    const { data, cover, page, pageCount } = this.state;
    return (
      <section className="Q-library">
        <div style={{ background: `url("${cover}") center/cover`, width: '100%', height: '100%' }}>
          <h1 className="Q-G-page-starting">{Translations.texts.library}</h1>
        </div>
        <div className="Q-content">
          <Filter onChange={this.onFilterChange}/>
          <div className="Q-list">
            {data.map(item => <Book key={item.id} details={item} />)}
            <Pagination pageNo={page} lastPageNo={pageCount} pageCount={pageCount} getList={this.getListByPageNo} />
          </div>
        </div>
      </section>
    );
  }
}

export default Library;