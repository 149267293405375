import React, { memo, useState } from 'react';

import RegulationsPDF from '../../../../assets/pdfs/regulations.pdf';
import Translations from '../../../../platform/services/translations';
import Modal from '../../../../components/modal';

import Pdficon from '../../../../assets/images/pdf.svg';

const Estabilishment = memo(() => {
  const [isRegulationsOpen, setRegulations] = useState(false);
  const toggleRegulations = () => setRegulations(!isRegulationsOpen);

  return <>
    <h3 className="Q-title">
      <span className="Q-G-title-tringle" /> {Translations.texts.establishment}
    </h3>

    <div className="Q-pdf-data">
      <div> <img alt="Pdf" src={Pdficon}/> </div>
      <div> <h4 className="Q-pdf-url" onClick={toggleRegulations}>{Translations.texts.regulations}</h4> </div>
    </div>
    <p>{Translations.texts.establishment_content}</p>
    {isRegulationsOpen && <Modal onClose={toggleRegulations}>
      <embed src={RegulationsPDF} type="application/pdf" className="Q-G-pdf-modal"/>
    </Modal>}
  </>
});

export default Estabilishment;