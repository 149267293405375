import React, { memo } from 'react';

import Translations from '../../../../platform/services/translations';

import './style.scss';

const AboutUs = memo(({item}) => <div className="Q-home-page-details">
  <div className="Q-media" style={{ background: `url(${item.featured_image}) center/cover` }} />
  <div className="Q-details">
    <h2 className="Q-section-title">{item.title}</h2>
    <p className="Q-section-body">{item.body}</p>
    <button className="Q-section-button">
      <a className="Q-G-radius-button Q-G-ghost-button" target="__blank" href={item.button_link}>{Translations.texts.read}</a>
    </button>
  </div>
</div>);

export default AboutUs;