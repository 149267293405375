import React, { memo, useState } from 'react';

import Translations from '../../../../platform/services/translations';
import MembersListPDF from '../../../../assets/pdfs/members-list.pdf';
import Modal from '../../../../components/modal';

import './style.scss';
import Apply from './components/apply';
import Pdficon from "../../../../assets/images/pdf.svg";

const Members = memo(() => {
  const [isApplyOpen, setApply] = useState(false);
  const [isMembersListOpen, setMembersList] = useState(false);

  const toggleApply = () => setApply(!isApplyOpen);
  const toggleMembersList = () => setMembersList(!isMembersListOpen);

  return <>
    <h3 className="Q-title"><span className="Q-G-title-tringle" /> {Translations.texts.members}</h3>
    {Translations.texts.members_content}
    <button className="Q-G-radius-button Q-members-apply-button" onClick={toggleApply}>{Translations.texts.apply}</button>


    <div className="Q-pdf-data">
      <div> <img alt="Pdf" src={Pdficon}/> </div>
      <div> <h4 className="Q-pdf-url" onClick={toggleMembersList}>{Translations.texts.members_list}</h4> </div>
    </div>

    {isApplyOpen && <Apply onClose={toggleApply} />}
    {isMembersListOpen && <Modal onClose={toggleMembersList}>
      <embed src={MembersListPDF} type="application/pdf" className="Q-G-pdf-modal"/>
    </Modal>}
  </>; 
});

export default Members;