import ConnectionV2 from "../services/connectionV2";

class HomeController {
  
  static getSlide = async () => {
    const controllerName = 'section';
    const result = await ConnectionV2.GET(controllerName, 'slider');
    return result;
  }

  static getSections = async () => {
    const controllerName = 'section';
    const result = await ConnectionV2.GET(controllerName, 'list');
    return result;
  }

  static getNews = async () => {
    const controllerName = 'news';
    const result = await ConnectionV2.GET(controllerName, 'home');
    return result;
  }

};

export default HomeController;