import React, { Component } from 'react';

import Translations from '../../../../platform/services/translations';
import { programFilterEnum } from '../../../../platform/constants/enums';

import SearchImage from '../../../../assets/images/search.png';

import './style.scss';

class Filter extends Component {

  state = {
    filter: {
      search: ''
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (JSON.stringify(nextProps.filter) !== JSON.stringify(prevState.filter)) {
        const filter = {...nextProps.filter};
        return { filter };
    }
    return null;
  }

  changeType = (type) => {
    const { filter } = this.state;
    if (filter.type !== type) {
      if (!type && type !== 0) delete filter.type;
      else filter.type = type;
      this.setState({ filter }, () => this.props.onChange(filter));
    }
  }

  onSearchChange = e => {
    const { filter } = this.state;
    filter.search = e.target.value;
    this.setState({ filter }, () => this.props.onChange(filter));
  }

  render() {
    const { filter } = this.state;

    return (
      <div className="Q-program-filter">
        <div className="Q-search">
          <img src={SearchImage} alt="search" />
          <input name="search" value={filter.search} placeholder={Translations.texts.search} onChange={this.onSearchChange} />
        </div>
        <div className="Q-types">
          <h4 className={!filter.type && filter.type !== 0 ? 'Q-chosen' : ''} onClick={() => this.changeType()}>{Translations.texts.all}</h4>
          <h4 className={filter.type === programFilterEnum.active ? 'Q-chosen' : ''} onClick={() => this.changeType(programFilterEnum.active)}>{Translations.texts.active}</h4>
          <h4 className={filter.type === programFilterEnum.finished ? 'Q-chosen' : ''} onClick={() => this.changeType(programFilterEnum.finished)}>{Translations.texts.finished}</h4>
        </div>
      </div>
    )
  }
};

export default Filter;