import React, {PureComponent} from 'react';

import Filter from './components/filter';
import Book from './components/book';
import Translations from '../../platform/services/translations';
import NewspapersController from '../../platform/api/newspapers';
import FileController from '../../platform/api/file';
import Connection from '../../platform/services/connection';

import newsPaperCover from '../../assets/images/newspaper.jpg'

import Pagination from '../../components/pagination';

import './style.scss';
import {pageCoverTypeEnum} from '../../platform/constants/enums';

class Newspaper extends PureComponent {

	state = {
		data: [],

		page: 1,
		perPage: 6,
		pageCount: 0,

		loading: true,
		cover: '',
	};

	async componentDidMount() {
		this.getList();
		this.getCover();
	}

	getList = async overwrite => {
		if (overwrite) Connection.AbortAll();
		const {page, perPage, filter} = this.state;
		this.setState({loading: true }, async () => {
			const result = await NewspapersController.getList({perPage, page, ...filter});
			if (result && result.success) {
				this.setState({
					data: result.data.itemList,
					pageCount: result.data.pageCount,
					loading: false
				});
			}
		});
	}

	getListByPageNo = (page) => {
		this.setState({ page }, () => {
			this.getList();
		});
	}

	getCover = async () => {
		const result = await FileController.getCover(pageCoverTypeEnum.newspaper);
		if (result && result.success) this.setState({cover: result.data});
		else this.setState({cover: newsPaperCover});
	}

	onFilterChange = filter => this.setState({ filter, page: 1, pageCount: 0 }, () => this.getList())

	render() {
		const {data, cover, pageCount, page} = this.state;

		return (
			<section className="Q-newspaper">
					<>
						<div style={{background: `url("${cover}") center/cover`, width: '100%', height: '100%'}}>
							<h1 className="Q-G-page-starting">{Translations.texts.newspaper}</h1>
						</div>
						<div className="Q-content">
							<Filter onChange={this.onFilterChange}/>
							<div className="Q-list">
								{data.map(item => <Book key={item.id} details={item}/>)}
							</div>
						</div>
						{
							data.length
							?
							<Pagination pageNo={page} lastPageNo={pageCount} pageCount={pageCount} getList={this.getListByPageNo}/>
							:
							null
						}
					</>
			</section>
		);
	}
}

export default Newspaper;