import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';

import ROUTES from '../../../../platform/constants/routes';
import NewsController from '../../../../platform/api/news';
import Translations from '../../../../platform/services/translations';
import Modal from '../../../../components/modal';

import CalendarIcon from '../../../../assets/images/calendar.png';
import DownloadIcon from '../../../../assets/images/download-white.png';

import './style.scss';

class Details extends PureComponent {

  state = {
    data: null,
    details: null,
  };

  componentDidMount() {
    this.fetchData();
    this.historyUnlisten = window.routerHistory.listen(() => setTimeout(this.fetchData, 0));
  }

  componentWillUnmount() { this.historyUnlisten(); }

  fetchData = async () => {
    const { id } = this.props.match.params;
    const result = await NewsController.details(id);

    if (!result || !result.success) window.routerHistory.push(ROUTES.HOME);
    else {
      // const body = result.data.body;
      // const splitted = body.split('</p>');
      // if (splitted.length > 1) {
      //   let concat = splitted[0];
      //   for (let i = 1; i < splitted.length - 1; i++) {
      //     concat += '<br/>' + splitted[i];
      //   }
      //   result.data.body = concat;
      // }
      this.setState({ data: result.data });
    }
  }

  prev = () => {
    const { data } = this.state;
    window.routerHistory.replace(ROUTES.NEWS_DETAILS.replace(':id', data.related.prev));
  }

  next = () => {
    const { data } = this.state;
    window.routerHistory.replace(ROUTES.NEWS_DETAILS.replace(':id', data.related.next));
  }

  getFormattedDate = date => {
    const splited = date.split(' ')[0];
    const dateArr = splited.split('-');
    return `${dateArr[0]}-${Translations.texts.months[+dateArr[1] - 1]}-${dateArr[2]}`;
  }

  formatDate = (date, withHours) => {
    if (!date) return 'N/A';
    const localDate = new Date(date);
    const months = Translations.texts.months;
  
    const onlyHours = localDate.getHours() < 10 ? `0${localDate.getHours()}` : localDate.getHours();
    const onlyMinutes = localDate.getMinutes() < 10 ? `0${localDate.getMinutes()}` : localDate.getMinutes();
  
    const hours = `${onlyHours}:${onlyMinutes}`;
  
    return `${localDate.getDate()} ${months[localDate.getMonth()]} ${localDate.getFullYear()}${withHours ? ` | ${hours}` : ''}`;
  }

  render() {
    const { data, details } = this.state;

    return (
      <section className="Q-news-details">
        {data ? <>
          <div className="Q-cover" style={{ background: `url("${data.cover}") center/cover` }} />
          <div className="Q-wrapper">
            <div className="Q-content">
              <h3>{data.title}</h3>
              <div className="Q-htmlBlock" dangerouslySetInnerHTML={{ __html: data.body }} />
              {!!data.attached.photos.length && <>
                <h3>{Translations.texts.photos}</h3>
                <div className="Q-details-photos">
                  {data.attached.photos.map(item => <div
                    key={item.id}
                    style={{ background: `url("${item.image}") center/cover` }}
                  >
                    <h3 onClick={() => this.setState({ details: item })}>{item.title}</h3>
                  </div>)}
                </div>
              </>}
              {!!data.attached.files.length && <>
                <h3>{Translations.texts.files}</h3>
                <div className="Q-details-files">
                  {data.attached.files.map(item => <a className="Q-file-link-download"
                    key={item.id}
                    href={item.image}
                    download
                  >
                   <div className="Q-file-download-block">
                      <div className="Q-file-download-icon">
                        <img alt={item.title} src={DownloadIcon} style={{width:'25px'}}></img>
                      </div>
                      <div className="Q-file-download-title">
                        {item.title} 
                      </div>
                   </div> 
                  </a>)}
                </div>
              </>}
            </div>
            {!!data.related.recomended.length && <div className="Q-recomended">
              <h4>{Translations.texts.recomended}</h4>
              {data.related.recomended.map(item => <Link key={item.id} to={ROUTES.NEWS_DETAILS.replace(':id', item.id)}>
                <div className="Q-image" style={{ background: `url("${item.image}") center/cover` }} />
                <div className="Q-content">
                  <h4>{getFormatted(item.title)}</h4>
                  <h5><img src={CalendarIcon} alt="calendar" /> <span>{this.formatDate(item.date)}</span></h5>
                </div>
              </Link>)}
            </div>}
            {(!!data.related.prev || !!data.related.next) && <div className="Q-bottom">
              {!!data.related.prev && <button onClick={this.prev} className="Q-prev"><i className="icon-ic_back" /> <span>{Translations.texts.prev}</span></button>}
              {!!data.related.next && <button onClick={this.next} className="Q-next"><span>{Translations.texts.next}</span> <i className="icon-ic_next" /></button>}
            </div>}
          </div>
          {details && <Modal onClose={() => this.setState({ details: null })}>
            <div
              className="Q-G-media-details-modal"
              style={{ background: `url("${details.image}") center/contain no-repeat` }}
            />
          </Modal>}
        </> : null}
      </section>
    );
  }
};

function getFormatted (description) {
	let formatted = description.split(' ').slice(0, 40).join(' ');
	if ([':', '...'].includes(formatted[formatted.length - 1])) {
		return formatted;
	} else if ([',', '`', '.'].includes(formatted[formatted.length - 1])) {
		return formatted.substr(0, formatted.length - 1) + '...'
	} else {
		return formatted + '...'
	}
}

export default withRouter(Details);