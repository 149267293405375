import Connection from "../services/connection";

const contollerName = 'contact';

class ContactController {

  static NewMessage = async body => {
    const result = await Connection.POST(contollerName, 'new-message', body);
    return result;
  };
};

export default ContactController;