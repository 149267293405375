import React, { memo } from 'react';

import Translations from '../../../../platform/services/translations';

import './style.scss';

const DepartmentMembers = memo(() => <>
  <h3 className="Q-title"><span className="Q-G-title-tringle" /> {Translations.texts.department_members}</h3>
  {Translations.texts.department_members_list.map((item, index) => (
    <div className="Q-dep-member-item" key={index}>
      <p>{item}</p>
      <hr />
    </div>
  ))}
</>);

export default DepartmentMembers;