import React, { Component } from 'react';

import BooksController from '../../../../platform/api/books';
import Translations from '../../../../platform/services/translations';

import SearchImage from '../../../../assets/images/search.png';

import './style.scss';

class Filter extends Component {

  state = {
    categories: null,
    filter: {
      id: undefined,
      key: '',
    },
  };

  componentDidMount() { this.fetchCategories(); }

  fetchCategories = async () => {
    const result = await BooksController.getCategories();
    if (result && result.success) {
      this.setState({ categories: result.data });
    }
  }

  chooseCategory = id => {
    const { filter } = this.state;
    if (filter.id === id) filter.id = undefined;
    else filter.id = id;
    this.setState({ filter }, () => this.props.onChange(filter));
  }

  onSearchChange = e => {
    const { filter } = this.state;
    filter.key = e.target.value;
    this.setState({ filter }, () => this.props.onChange(filter));
  }

  render() {
    const { filter, categories } = this.state;

    return (
      <div className="Q-library-filter">
        <div className="Q-search">
          <img src={SearchImage} alt="search" />
          <input
            className="Q-G-field"
            placeholder={Translations.texts.search}
            value={filter.search}
            onChange={this.onSearchChange}
          />
        </div>
        {
          categories
          ?
          <div className="Q-categories">
            {categories.map(item => <h4
              key={item.id}
              title={item.name}
              className={filter.id === item.id ? 'Q-chosen' : ''}
              onClick={() => this.chooseCategory(item.id)}
            >{item.name}</h4>)}
          </div>
          :
          null 
        }
      </div>
    );
  }
};

export default Filter;